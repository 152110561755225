import { useState } from "react";
import clsx from "clsx";
import ReactDOM from "react-dom";
import { useFetchCollection } from "../../../../utils/fetchUtils";
import { NotificationType } from "../../../../models/Notification";
import { latestNotificationsInterface } from "../../../../utils/fetchData/fetchNotifications";
import { Markdown } from "../../../other/Markdown/Markdown";
import { useHistory } from "react-router-dom";

export const Notifications = () => {
  const history = useHistory();
  const [notificationIsOpen, setNotificationIsOpen] = useState<boolean>(false);

  const [notifications, notificationsLoading] =
    useFetchCollection<NotificationType>(latestNotificationsInterface, []);

  return (
    <>
      <div
        className="p-2 flex"
        onClick={() => setNotificationIsOpen((prevState) => !prevState)}
      >
        <i className="ws-icons ws-icons-alert" />
      </div>
      {notifications.length > 0 &&
        ReactDOM.createPortal(
          <div
            className={clsx(
              "fixed inset-0 transition-all bg-white bg-opacity-60 flex justify-center items-center p-4",
              notificationIsOpen
                ? "z-50 opacity-100"
                : "-z-10 opacity-0 pointer-events-none"
            )}
            onClick={() => setNotificationIsOpen(false)}
          >
            <div className="bg-primary rounded-lg px-4 py-1 text-white w-full relative">
              <i className="ri-close-line absolute right-3 top-3 text-secondary text-lg h-4 flex items-center" />
              <div className="divide-y divide-white">
                {notifications.map((notification) => (
                  <div
                    className="py-4"
                    key={notification.attributes.headline}
                    onClick={(event) => {
                      event.preventDefault();
                      notification.attributes.news.data &&
                        history.push(
                          `/news/${notification.attributes.news.data.attributes.slug}`
                        );
                    }}
                  >
                    <h2 className="font-headline text-h4 mb-1">
                      {notification.attributes.headline}
                    </h2>
                    <Markdown className="text-copy-small">
                      {notification.attributes.text}
                    </Markdown>
                  </div>
                ))}
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
