export interface EventToolbarProps {
  search: string;
  onSearchChange: (search: string) => void;
  mode: "list" | "calendar";
  onToggleMode: () => void;
  sort: "ASC" | "DESC";
  onToggleSort: () => void;
}

export const EventToolbar = (props: EventToolbarProps) => {
  return (
    <div className="flex justify-between items-center mb-8">
      <div className="relative w-full">
        <input
          type="text"
          className="ws-input pr-12 shadow-none"
          placeholder="Event suchen"
          value={props.search}
          onChange={(event) => props.onSearchChange(event.target.value)}
        />
        <i className="ws-icons ws-icons-suche absolute right-5 top-1/2 -translate-y-1/2 text-lg" />
      </div>
      {/*<i*/}
      {/*  className={clsx([*/}
      {/*    "text-lg cursor-pointer",*/}
      {/*    props.mode === "list" ? "ri-calendar-line" : "ri-list-unordered",*/}
      {/*  ])}*/}
      {/*  onClick={props.onToggleMode}*/}
      {/*/>*/}
      {/*<i*/}
      {/*  className={clsx([*/}
      {/*    "text-lg cursor-pointer",*/}
      {/*    props.sort === "DESC" ? "ri-sort-desc" : "ri-sort-asc",*/}
      {/*  ])}*/}
      {/*  onClick={props.onToggleSort}*/}
      {/*/>*/}
    </div>
  );
};
