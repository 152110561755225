import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { Button, ButtonColor } from "../components/other/Button/Button";

export const NotFoundPage = () => {
  return (
    <AuthenticatedPage>
      <div className="pt-40 container flex flex-col items-center">
        <h1 className="font-headline text-2xl">404</h1>
        <h3>Dieser Link existiert leider nicht.</h3>
        <Button color={ButtonColor.PRIMARY} link="/" className="mt-8">
          Zurück zum Start
        </Button>
      </div>
    </AuthenticatedPage>
  );
};
