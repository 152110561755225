import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { useFetchCollection } from "../utils/fetchUtils";
import { EventType } from "../models/Event";
import { eventOverviewInterface } from "../utils/fetchData/fetchEvents";
import { allEventsCategoriesInterface } from "../utils/fetchData/fetchCategories";
import { useEffect, useState } from "react";
import { EventsCategoryType } from "../models/EventsCategory";
import { CategoryFilter } from "../components/sections/CategoryFilter/CategoryFilter";
import { EventsList } from "../components/sections/EventsList/EventsList";
import { Calendar } from "../components/other/Calendar/Calendar";
import {
  EventToolbar,
  EventToolbarProps,
} from "../components/other/EventToolbar/EventToolbar";
import { useLazyInput } from "../utils/useLazyInput";
import { HomeButton } from "../components/other/HomeButton/HomeButton";

export const EventsPage = () => {
  const pageSize = 10;
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  const [limit, setLimit] = useState<number>(pageSize);
  const [mode, setMode] = useState<EventToolbarProps["mode"]>("list");
  const [sort, setSort] = useState<EventToolbarProps["sort"]>("DESC");

  const [search, setSearch, lastSearchFetch] = useLazyInput();

  useEffect(() => {
    setLimit(pageSize);
  }, [categoryFilter]);

  const [events, eventsLoading, eventsMeta] = useFetchCollection<EventType>(
    {
      ...eventOverviewInterface,
      filter: [
        ...(categoryFilter
          ? [
              {
                collectionType: "category",
                attribute: "slug",
                value: categoryFilter,
              },
            ]
          : []),
        {
          collectionType: "title",
          attribute: "$containsi",
          value: lastSearchFetch,
        },
      ],
      pagination: { start: 0, limit },
    },
    [categoryFilter, limit, lastSearchFetch]
  );

  const [categories, categoriesLoading] =
    useFetchCollection<EventsCategoryType>(allEventsCategoriesInterface, []);

  const onLoadMoreButtonClick =
    (eventsMeta.pagination.total ?? 1) > limit
      ? () => {
          setLimit((prevState) => prevState + pageSize);
        }
      : undefined;

  return (
    <AuthenticatedPage hideHeader>
      <div className="container py-4">
        <HomeButton />
      </div>
      <CategoryFilter
        categories={categories.map((cat) => ({
          label: cat.attributes.name,
          value: cat.attributes.slug,
        }))}
        categoryFilter={categoryFilter}
        setCategoryFilter={(slug) => setCategoryFilter(slug)}
      />
      <div className="pt-10 pb-15 container">
        <EventToolbar
          search={search}
          onSearchChange={(newSearch) => setSearch(newSearch)}
          mode={mode}
          onToggleMode={() =>
            setMode((prevState) => (prevState === "list" ? "calendar" : "list"))
          }
          sort={sort}
          onToggleSort={() =>
            setSort((prevState) => (prevState === "DESC" ? "ASC" : "DESC"))
          }
        />
        <div className="flex justify-center">
          <Calendar provideBottomSpace />
        </div>
        <EventsList
          events={sort === "DESC" ? events : [...events].reverse()}
          loading={eventsLoading}
          onLoadMoreButtonClick={onLoadMoreButtonClick}
        />
      </div>
    </AuthenticatedPage>
  );
};
