import React from "react";
import { User } from "../models/User";

interface UserContextType {
  localUser: User | null;
}

export const UserContext = React.createContext<UserContextType>({
  localUser: null,
});
