import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";

export const useHideFooterOnKeyboardShow = () => {
  const [showFooter, setShowFooter] = useState<boolean>(true);

  useEffect(() => {
    if (Capacitor.isPluginAvailable("Keyboard")) {
      Keyboard.addListener("keyboardWillShow", () => {
        setShowFooter(false);
      });
      Keyboard.addListener("keyboardDidHide", () => {
        setShowFooter(true);
      });
      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  return showFooter;
};
