import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRoute,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import "remixicon/fonts/remixicon.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Theme variables */
import "./theme/variables.css";

import "./styles/globals.scss";
import { User } from "./models/User";
import React, { useEffect, useState } from "react";
import { Spinner } from "./components/other/Spinner/Spinner";
import { SESSION_COOKIE_NAME } from "./utils/constants";
import dayjs from "dayjs";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";
import Login from "./pages/Login";
import { UserContext } from "./utils/UserContext";
import NewsPage from "./pages/News";
import { AuthenticatedLayout } from "./components/layouts/AuthenticatedLayout/AuthenticatedLayout";
import { NewsDetail } from "./pages/NewsDetail";
import { EventsPage } from "./pages/Events";
import { EventDetail } from "./pages/EventDetail";
import { KnowledgePage } from "./pages/KnowledgePage";
import { InternalPage } from "./pages/Internal";
import { InternalDetail } from "./pages/InternalDetail";
import { ProfilePage } from "./pages/Profile";
import qs from "qs";
import { AppUrlListener } from "./components/other/AppUrlListener/AppUrlListener";
import { ForgotPassword } from "./pages/ForgotPassword";
import { PasswordReset } from "./pages/PasswordReset";
import { Registration } from "./pages/Registration";
import { EmailConfirmation } from "./pages/EmailConfirmation";
import { NotFoundPage } from "./pages/NotFoundPage";

setupIonicReact();

const App = () => {
  const [localUser, setLocalUser] = useState<User | null>(null);
  const [loginLoading, setLoginLoading] = useState<boolean>(true);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      try {
        window.screen.orientation.lock("portrait");
      } catch (e: any) {
        console.log(e);
      }
    }
  }, []);

  useEffect(() => {
    if (
      !loginLoading &&
      !localUser &&
      ![
        "/login",
        "/password-reset",
        "/forgot-password",
        "/registration",
        "/email-confirmation",
      ].includes(window.location.pathname)
    ) {
      window.location.replace("/login");
    }
  }, [loginLoading, localUser]);

  const getSessionPreferences: () => Promise<{
    jwt: string;
    expires: string;
  } | null> = async () => {
    const prefs =
      (await Preferences.get({ key: SESSION_COOKIE_NAME })).value ?? "";
    if (prefs) {
      return JSON.parse(prefs) as { jwt: string; expires: string };
    }
    return null;
  };

  useEffect(() => {
    const fetchLocalUser = async (jwt: string) => {
      const query = qs.stringify({ populate: ["readNotifications"] });
      const localFetch = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/me?${query}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (localFetch.ok) {
        return await localFetch.json();
      }
    };
    getSessionPreferences().then((pref) => {
      if (
        pref &&
        pref.jwt &&
        pref.jwt.length > 0 &&
        dayjs(pref.expires).isAfter(dayjs())
      ) {
        fetchLocalUser(pref.jwt)
          .then((res) => {
            if (res) {
              setLocalUser({ ...res, jwt: pref.jwt });
            }
          })
          .finally(() => setLoginLoading(false));
      } else {
        setLoginLoading(false);
      }
    });
  }, []);

  if (loginLoading) {
    return <Spinner />;
  }

  return (
    <IonApp>
      <UserContext.Provider value={{ localUser }}>
        <IonReactRouter>
          <AppUrlListener />
          <AuthenticatedLayout>
            <IonRouterOutlet>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/news" exact>
                <NewsPage />
              </Route>
              <Route path="/events" exact>
                <EventsPage />
              </Route>
              <Route path="/knowledge" exact>
                <KnowledgePage />
              </Route>
              <Route path="/internal" exact>
                <InternalPage />
              </Route>
              <Route path="/profile" exact>
                <ProfilePage />
              </Route>
              <Route path="/internal/:slug">
                {(props) => <InternalDetail slug={props.match?.params.slug} />}
              </Route>
              <Route path="/news/:slug">
                {(props) => <NewsDetail slug={props.match?.params.slug} />}
              </Route>
              <Route path="/events/:slug">
                {(props) => <EventDetail slug={props.match?.params.slug} />}
              </Route>
            </IonRouterOutlet>
          </AuthenticatedLayout>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/registration" exact>
            <Registration />
          </Route>
          <Route path="/password-reset" exact>
            <PasswordReset />
          </Route>
          <Route path="/email-confirmation" exact>
            <EmailConfirmation />
          </Route>
          {/*<Route path="/404">*/}
          {/*  <NotFoundPage />*/}
          {/*</Route>*/}
        </IonReactRouter>
      </UserContext.Provider>
    </IonApp>
  );
};

export default App;
