import { EventType } from "../../../models/Event";
import {
  Calendar as CalendarComponent,
  ViewCallbackProperties,
} from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import { useFetchCollection } from "../../../utils/fetchUtils";
import { eventOverviewInterface } from "../../../utils/fetchData/fetchEvents";
import { useMemo, useState } from "react";
import clsx from "clsx";
import { CalendarDetail } from "./CalendarDetail/CalendarDetail";

interface CalendarProps {
  provideBottomSpace?: boolean;
}

export const Calendar = (props: CalendarProps) => {
  const [fromDate, setFromDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(2, "month")
  );
  const [untilDate, setUntilDate] = useState<dayjs.Dayjs>(
    dayjs().add(2, "month")
  );
  const [activeDate, setActiveDate] = useState<dayjs.Dayjs | null>(null);

  const [events, eventsLoading] = useFetchCollection<EventType>(
    {
      ...eventOverviewInterface,
      filter: [
        {
          collectionType: "date",
          attribute: "$gte",
          value: fromDate.format("YYYY-MM-DD"),
        },
        {
          collectionType: "date",
          attribute: "$lte",
          value: untilDate.format("YYYY-MM-DD"),
        },
      ],
    },
    [untilDate, fromDate]
  );

  const activeEvent = useMemo(
    () =>
      events.find((event) =>
        dayjs(event.attributes.date).isSame(activeDate, "date")
      ),
    [activeDate, events]
  );

  const handleTileClick = (date: Date) => {
    setActiveDate((prevState) =>
      dayjs(date).isSame(prevState, "date") ? null : dayjs(date)
    );
  };

  const handleDateChange = (event: ViewCallbackProperties) => {
    setFromDate(dayjs(event.activeStartDate).subtract(2, "months"));
    setUntilDate(dayjs(event.activeStartDate).add(2, "months"));
  };

  const handleArrowClick = (direction: "next" | "back") => {
    let eventToFind: EventType | undefined = undefined;
    if (activeEvent) {
      if (direction === "next") {
        eventToFind = [...events]
          .reverse()
          .find(
            (event) =>
              event.id !== activeEvent.id &&
              dayjs(event.attributes.date).isAfter(dayjs(activeDate))
          );
        eventToFind && setActiveDate(dayjs(eventToFind.attributes.date));
        return;
      }
      eventToFind = events.find(
        (event) =>
          event.id !== activeEvent.id &&
          dayjs(event.attributes.date).isBefore(dayjs(activeDate))
      );
      eventToFind && setActiveDate(dayjs(eventToFind.attributes.date));
    }
  };

  return (
    <div
      className={clsx([
        "pb-16",
        props.provideBottomSpace && activeEvent && "h-[36rem]",
      ])}
    >
      <CalendarComponent
        locale="de"
        prev2Label={null}
        next2Label={null}
        nextLabel={<i className="ri-arrow-right-s-line text-white" />}
        prevLabel={<i className="ri-arrow-left-s-line text-white" />}
        view={"month"}
        tileClassName={(tile) =>
          clsx([
            events.find(
              (event) =>
                dayjs(event.attributes.date).toDate().getTime() ===
                tile.date.getTime()
            ) && "event",
            activeDate?.toDate().getTime() === tile.date.getTime() && "active",
          ])
        }
        onClickDay={handleTileClick}
        onActiveStartDateChange={handleDateChange}
      />
      {activeEvent && (
        <CalendarDetail
          activeEvent={activeEvent}
          onArrowClick={handleArrowClick}
        />
      )}
    </div>
  );
};
