import { NewsItemType } from "../../../models/NewsItem";
import { NewsCard } from "../../other/NewsCard/NewsCard";
import { Button, ButtonColor } from "../../other/Button/Button";

interface MoreNewsProps {
  items: NewsItemType[];
}

export const MoreNews = (props: MoreNewsProps) => {
  return (
    <section>
      <div
        className="h-2.5 w-full bg-full"
        style={{
          backgroundImage: "url('/img/goldlinie_oben.png')",
        }}
      />
      <div className="container pt-7 pb-15">
        <h2 className="mb-8 text-h1 font-headline text-center">
          Andere Artikel
        </h2>
        <div className="grid grid-cols-1 gap-5 auto-rows-fr"></div>
        {props.items.map((newsItem) => (
          <NewsCard {...newsItem} key={newsItem.id} />
        ))}
        <div className="flex justify-center mt-8">
          <Button color={ButtonColor.INVERTED} className="w-52" link="/news">
            Mehr anzeigen
          </Button>
        </div>
      </div>
    </section>
  );
};
