import { KnowledgeItemType } from "../../../models/KnowledgeItem";
import { Markdown } from "../../other/Markdown/Markdown";
import { Button, ButtonColor } from "../../other/Button/Button";

interface KnowledgeTeaserProps {
  item: KnowledgeItemType;
  isLoading?: boolean;
}

export const KnowledgeTeaser = (props: KnowledgeTeaserProps) => {
  return (
    <div className="mt-20 mb-10 text-center">
      <h2 className="font-headline text-h2 italic text-primary">
        “{props.item.attributes.name}”
      </h2>
      <Markdown className="mt-6">{props.item.attributes.text}</Markdown>
      <Button
        color={ButtonColor.INVERTED}
        link="/knowledge"
        className="mt-4 w-52"
      >
        Mehr erfahren
      </Button>
    </div>
  );
};
