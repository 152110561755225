import { ImagesType } from "../../../../models/components/Images";
import clsx from "clsx";
import { useState } from "react";
import { ImageCard } from "../../../other/ImageCard/ImageCard";

export const ContentImages = (props: ImagesType) => {
  const [showModalSlide, setShowModalSlide] = useState<number>(-1);

  if (props.images.data.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx([
        "mt-8 gap-2.5 sm:grid",
        props.images.data.length === 2 ? "grid grid-cols-2" : "grid-cols-3",
      ])}
    >
      {props.images.data.length > 0 && (
        <>
          {props.images.data.length === 1 ? (
            <ImageCard zoom className="col-span-3" {...props.images.data[0]} />
          ) : (
            <img
              src={
                process.env.REACT_APP_API_URL +
                (props.images.data[0].attributes.formats.small?.url ??
                  props.images.data[0].attributes.url)
              }
              className={clsx([
                "relative col-span-2 mb-2.5 aspect-[33/20] object-cover w-full overflow-hidden" +
                  "cursor-pointer rounded-lg sm:mb-0 sm:h-full shadow-alert",
                props.images.data.length === 1
                  ? "col-span-3"
                  : "sm:aspect-auto",
                props.images.data.length === 2 &&
                  "col-span-1 !mb-0 !aspect-square",
              ])}
              onClick={() => setShowModalSlide(0)}
              alt={props.images.data[0].attributes.alternativeText}
            />
          )}
          {props.images.data.length === 1 &&
            props.images.data[0].attributes.caption && (
              <p className="col-span-3 mt-4 text-xs font-bold text-grey-dark">
                {props.images.data[0].attributes.caption}
              </p>
            )}
        </>
      )}
      {props.images.data.length === 2 ? (
        <img
          src={
            process.env.REACT_APP_API_URL +
            (props.images.data[1].attributes.formats.small?.url ??
              props.images.data[1].attributes.url)
          }
          className={clsx([
            "relative aspect-square w-full cursor-pointer overflow-hidden object-cover rounded-lg shadow-alert",
          ])}
          onClick={() => setShowModalSlide(1)}
          alt={props.images.data[1].attributes.alternativeText}
        />
      ) : (
        <div className="flex space-x-2.5 sm:flex-col sm:space-x-0 sm:space-y-2.5">
          {props.images.data.length > 2 && (
            <>
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  (props.images.data[1].attributes.formats.small?.url ??
                    props.images.data[1].attributes.url)
                }
                className={clsx([
                  "relative aspect-square w-full cursor-pointer overflow-hidden object-cover rounded-lg shadow-alert w-1/2",
                ])}
                onClick={() => setShowModalSlide(1)}
                alt={props.images.data[1].attributes.alternativeText}
              />
              <div className="relative w-1/2 aspect-square">
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    (props.images.data[2].attributes.formats.small?.url ??
                      props.images.data[2].attributes.url)
                  }
                  className="cursor-pointer overflow-hidden rounded-lg shadow-alert w-full"
                  onClick={() => setShowModalSlide(2)}
                  alt={props.images.data[2].attributes.alternativeText}
                />
                {props.images.data.length > 3 && (
                  <div className="absolute inset-0 flex items-center rounded-lg justify-center bg-brown bg-opacity-80 text-h2 font-bold text-white font-headline">
                    {"+" + (props.images.data.length - 3)}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {/*{showModalSlide >= 0 && (*/}
      {/*  <SwiperModal*/}
      {/*    onClose={() => setShowModalSlide(-1)}*/}
      {/*    initialSlide={showModalSlide}*/}
      {/*    medias={props.images.data}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};
