import React, { useEffect, useRef, useState } from "react";

export const useLazyInput: () => [
  string,
  React.Dispatch<React.SetStateAction<string>>,
  string
] = () => {
  const [input, setInput] = useState<string>("");
  const [delayedInput, setDelayedInput] = useState<string>("");

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (input !== delayedInput) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setDelayedInput(input);
      }, 500);
    }
  }, [input, delayedInput]);

  return [input, setInput, delayedInput];
};
