import qs from "qs";
import { useContext, useEffect, useState } from "react";
import {
  FetchDataInterface,
  PaginationType,
  PaginationWithLimitType,
  QueryType,
} from "./fetchTypes";
import { UserContext } from "./UserContext";

export const fetchStrapiData = async (fetchInterface: FetchDataInterface) => {
  const queryString = buildQueryFromFetchInterface(fetchInterface);
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/${fetchInterface.name}?${queryString}`
  );
  return await res.json();
};

export const useFetchCollection = <T>(
  fetchData: FetchDataInterface,
  deps: React.DependencyList = [],
  keepDataInResponse?: boolean
): [
  T[],
  boolean,
  { pagination: PaginationType | PaginationWithLimitType },
  Error | null
] => {
  const { localUser } = useContext(UserContext);
  const [collection, setCollection] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(!!fetchData.name);
  const [error, setError] = useState<Error | null>(null);
  const [meta, setMeta] = useState<{
    pagination: PaginationType | PaginationWithLimitType;
  }>({
    pagination: { page: 0, pageSize: 10 },
  });

  const queryString = buildQueryFromFetchInterface(fetchData);
  useEffect(() => {
    setIsLoading(true);
    if (fetchData.name) {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/${fetchData.name}?${queryString}`,
        {
          headers: {
            "Content-Type": "application/json",
            ...(localUser?.jwt
              ? { Authorization: `Bearer ${localUser.jwt}` }
              : {}),
          },
        }
      ).then((result) =>
        result
          .json()
          .then((result) => {
            setCollection(result.data === null ? [] : (result.data as T[]));
            if (result.meta && result.meta.pagination) {
              setMeta(result.meta);
            }
            setIsLoading(false);
            setError(null);
          })
          .catch((e) => {
            console.error(e, fetchData.name);
            setIsLoading(false);
            setError(e);
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, localUser]);
  return [collection, isLoading, meta, error];
};

export const useFetchSingleData = <T>(
  fetchData: FetchDataInterface,
  deps: React.DependencyList = [],
  keepDataInResponse?: boolean
): [T | undefined, boolean, Error | null] => {
  const { localUser } = useContext(UserContext);
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(!!fetchData.name);
  const [error, setError] = useState<Error | null>(null);

  const queryString = buildQueryFromFetchInterface(fetchData);
  useEffect(() => {
    setIsLoading(true);
    if (fetchData.name) {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/${fetchData.name}?${queryString}`,
        {
          headers: new Headers({
            "Content-Type": "application/json",
            ...(localUser?.jwt
              ? { Authorization: `Bearer ${localUser.jwt}` }
              : {}),
          }),
        }
      ).then((result) =>
        result
          .json()
          .then((result) => {
            if (result.error) {
              setError(result.error);
            } else {
              setError(null);
            }
            setData(result.data === null ? undefined : (result.data as T));
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e, fetchData.name);
            setIsLoading(false);
            setError(e);
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, localUser]);

  return [data, isLoading, error];
};

const buildQueryFromFetchInterface = ({
  dataQuery,
  name,
  filter,
  pagination,
  sort,
  preview,
}: FetchDataInterface) => {
  let query: QueryType = { populate: dataQuery ?? [] };
  if (filter && filter.length > 0) {
    query.filters = {
      $and: filter.map((filterItem) =>
        filterItem.collectionType
          ? {
              [filterItem.collectionType]: {
                [filterItem.attribute]: filterItem.value,
              },
            }
          : {
              [filterItem.attribute]: filterItem.value,
            }
      ),
    };
  }
  if (pagination) {
    if ("page" in pagination) {
      query.pagination = {
        page: pagination.page,
        pageSize: pagination.pageSize,
      };
    }
    if ("start" in pagination) {
      query.pagination = {
        page: pagination.start,
        pageSize: pagination.limit,
      };
    }
  }
  if (sort && sort.length > 0) {
    query.sort = sort;
  }
  if (preview) {
    query.publicationState = "preview";
  }
  return qs.stringify(query);
};
