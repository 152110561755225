import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { Spinner } from "../components/other/Spinner/Spinner";
import { useFetchCollection, useFetchSingleData } from "../utils/fetchUtils";
import { ArticleContent } from "../components/sections/ArticleContent/ArticleContent";
import { EventType } from "../models/Event";
import { MoreEvents } from "../components/sections/MoreEvents/MoreEvents";
import { useDarkTextColorToolbar } from "../utils/useDarkTextColorToolbar";
import { NotFoundPage } from "./NotFoundPage";

interface EventDetailProps {
  slug?: string;
}

export const EventDetail = (props: EventDetailProps) => {
  useDarkTextColorToolbar();

  const [eventItem, eventItemLoading, error] = useFetchSingleData<EventType>(
    {
      name: props.slug ? "events/" + props.slug : "",
      dataQuery: ["mainImage", "content.images", "category"],
    },
    [props.slug]
  );

  const [additionalEvents] = useFetchCollection<EventType>(
    {
      name: "events",
      filter: [
        ...(eventItem?.attributes.category.data
          ? [
              {
                collectionType: "category",
                attribute: "slug",
                value: eventItem?.attributes.category.data.attributes.slug,
              },
              {
                collectionType: "id",
                attribute: "$ne",
                value: eventItem.id.toString(),
              },
            ]
          : []),
      ],
      pagination: { limit: 3, start: 0 },
      sort: ["date:DESC"],
      dataQuery: ["mainImage", "content.images", "category"],
    },
    [eventItem]
  );

  if (!props.slug || error) {
    return <NotFoundPage />;
  }

  return (
    <AuthenticatedPage hideHeader>
      {eventItemLoading || !eventItem ? (
        <Spinner />
      ) : (
        <div>
          <ArticleContent {...eventItem} />
          {additionalEvents.length > 0 && (
            <MoreEvents items={additionalEvents} />
          )}
        </div>
      )}
    </AuthenticatedPage>
  );
};
