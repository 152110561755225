import {
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

export const useDarkTextColorToolbar = () => {
  useIonViewWillEnter(() => {
    if (Capacitor.isPluginAvailable("StatusBar") && isPlatform("ios")) {
      StatusBar.setStyle({ style: Style.Light });
    }
  }, []);

  useIonViewWillLeave(() => {
    if (Capacitor.isPluginAvailable("StatusBar") && isPlatform("ios")) {
      StatusBar.setStyle({ style: Style.Dark });
    }
  }, []);
};
