import { EventType } from "../../../models/Event";
import { Button, ButtonColor } from "../../other/Button/Button";
import { EventCard } from "../../other/EventCard/EventCard";

interface MoreEventsProps {
  items: EventType[];
}

export const MoreEvents = (props: MoreEventsProps) => {
  return (
    <section>
      <div
        className="h-2.5 w-full bg-full"
        style={{
          backgroundImage: "url('/img/goldlinie_oben.png')",
        }}
      />
      <div className="container pt-7 pb-15">
        <h2 className="mb-8 text-h1 font-headline text-center">
          Weitere Events
        </h2>
        <div className="grid grid-cols-1 gap-5 auto-rows-fr">
          {props.items.map((eventItem) => (
            <EventCard {...eventItem} key={eventItem.id} />
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <Button color={ButtonColor.INVERTED} className="w-52" link="/events">
            Mehr anzeigen
          </Button>
        </div>
      </div>
    </section>
  );
};
