import { NotificationType } from "../../../models/Notification";
import { Markdown } from "../../other/Markdown/Markdown";

export const ImportantAlert = (props: NotificationType) => {
  return (
    <div className="bg-secondary shadow-alert">
      <div className="container pt-24 pb-8 text-center text-white">
        <h2 className="mb-6 font-headline text-h2">
          {props.attributes.headline}
        </h2>
        <Markdown>{props.attributes.text}</Markdown>
      </div>
      <div
        className="h-2.5 w-full bg-repeat"
        style={{ backgroundImage: "url(/img/goldlinie_unten.png)" }}
      ></div>
    </div>
  );
};
