import { useHistory } from "react-router-dom";
import clsx from "clsx";

interface BackButtonProps {
  className?: string;
}

export const BackButton = (props: BackButtonProps) => {
  const history = useHistory();
  return (
    <div
      className={clsx([
        "rounded-full shadow-alert bg-brown flex justify-center items-center w-12 h-12",
        props.className,
      ])}
      onClick={() => history.go(-1)}
    >
      <i className="ws-icons ws-icons-pfeil_back text-white text-lg" />
    </div>
  );
};
