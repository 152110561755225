import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../components/layouts/DefaultLayout/DefaultLayout";
import { Spinner } from "../components/other/Spinner/Spinner";
import { Button, ButtonColor } from "../components/other/Button/Button";

export const EmailConfirmation = () => {
  const [code, setCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setCode(
      new URLSearchParams(window.location.search).get("confirmation") ?? ""
    );
  }, []);

  useEffect(() => {
    if (code) {
      const confirmationFetch = async () => {
        try {
          setIsLoading(true);
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/email-confirmation?confirmation=${code}`
          );
          setIsLoading(false);
          if (!res.ok) {
            setError(
              "Etwas ist schiefgelaufen. Bitte benutzen Sie den Link aus der letzten E-Mail."
            );
          }
        } catch (e: any) {
          console.log(e);
        }
      };
      confirmationFetch();
    }
  }, [code]);

  return (
    <DefaultLayout>
      <div className="flex flex-1 justify-center bg-beige py-20 pt-24">
        <div className="max-w-[17.5rem]">
          <h1 className="mb-6 text-center font-headline text-h2">
            E-Mail bestätigen
          </h1>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <p className="text-center mt-20">
                {!code
                  ? "Der Account wurde nicht gefunden."
                  : error
                  ? error
                  : "Die E-Mail Addresse wurde bestätigt."}
              </p>
              <div className="mt-4 flex flex-col items-center">
                <Button
                  color={ButtonColor.SECONDARY}
                  type="submit"
                  link={"/login"}
                  className="mt-8 w-52"
                >
                  Zum Login
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};
