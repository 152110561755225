import { HomeButton } from "../../other/HomeButton/HomeButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { NewsItemType } from "../../../models/NewsItem";
import { Autoplay, Pagination } from "swiper";
import { ImageCard } from "../../other/ImageCard/ImageCard";
import { Button, ButtonColor } from "../../other/Button/Button";
import { EventType } from "../../../models/Event";
import clsx from "clsx";
import dayjs from "dayjs";

interface OverviewHeroProps {
  items: (NewsItemType | EventType)[];
  blueBg?: boolean;
}

export const OverviewHero = (props: OverviewHeroProps) => {
  return (
    <div
      className={clsx(["native-pt", props.blueBg ? "bg-primary" : "bg-brown"])}
    >
      <div
        className={clsx([
          "fixed top-0 left-0 z-50 native-pt w-full",
          props.blueBg ? "bg-primary" : "bg-brown",
        ])}
      />
      <div className="container pt-10 text-white pb-12">
        <HomeButton />
        <div className="mt-10">
          <Swiper
            modules={[Autoplay, Pagination]}
            loop
            initialSlide={1}
            className="-mx-2 news-swiper"
            grabCursor
            spaceBetween={20}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            speed={800}
          >
            {props.items.map((item) => (
              <SwiperSlide key={item.id} className="px-2 relative pb-16">
                {item.attributes.category.data && (
                  <h3 className="text-secondary text-preline font-bold uppercase mb-2.5">
                    {item.attributes.category.data.attributes.name}
                    {"date" in item.attributes &&
                      ` - ${dayjs(item.attributes.date).format("DD.MM.YYYY")}`}
                  </h3>
                )}
                <h2 className="text-h2 font-headline mb-8">
                  {"headline" in item.attributes
                    ? item.attributes.headline
                    : item.attributes.title}
                </h2>
                {item.attributes.mainImage.data && (
                  <ImageCard
                    {...item.attributes.mainImage.data}
                    className="mt-10"
                  />
                )}
                <p className="mt-8">{item.attributes.teaser}</p>
                <Button
                  color={ButtonColor.SECONDARY}
                  className="mt-8 w-52"
                  link={`/${
                    "headline" in item.attributes ? "news" : "events"
                  }/${item.attributes.slug}`}
                >
                  Mehr Lesen
                  <i className="ws-icons ws-icons-pfeil_back rotate-180 ml-8" />
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
