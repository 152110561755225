import { FetchDataInterface } from "../fetchTypes";

export const latestNewsInterface: FetchDataInterface = {
  name: "news",
  sort: ["releaseDate:desc"],
  dataQuery: ["mainImage", "content.images", "category"],
  pagination: { page: 1, pageSize: 10 },
};

export const heroNewsInterface: FetchDataInterface = {
  name: "news",
  sort: ["releaseDate:desc"],
  dataQuery: ["mainImage", "content.images", "category"],
  pagination: { page: 1, pageSize: 3 },
};
