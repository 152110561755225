import React, { useState } from "react";
import { Button, ButtonColor } from "../../other/Button/Button";
import { UserDataType } from "../FindUserByBaseData/FindUserByBaseData";
import clsx from "clsx";
import { Link } from "react-router-dom";

export interface RegistrationFormType {
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface RegistrationFormProps {
  userId: number;
  userData: UserDataType;
}

export const RegistrationForm = (props: RegistrationFormProps) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [input, setInput] = useState<RegistrationFormType>({
    email: "",
    password: "",
    passwordConfirmation: "",
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (
      !input.email.match(/^[A-Za-z0-9._%+-]+@weihenstephaner\.(de|eu|com)$/g) &&
      !input.email.match(/^[A-Za-z0-9._%+-]+@monacofriends\.(com)$/g)
    ) {
      setError(
        "Nur E-Mail Addressen mit @weihenstephaner.de, .eu oder .com sind erlaubt"
      );
      return;
    }
    if (input.password !== input.passwordConfirmation) {
      setError("Die Passwörter stimmen nicht überein");
      return;
    }
    setIsLoading(true);
    try {
      const registrationFetch = await fetch(
        process.env.REACT_APP_API_URL + "/api/users/updateUserByInternalData",
        {
          body: JSON.stringify({
            firstName: props.userData.firstName,
            lastName: props.userData.lastName,
            employeeNumber: props.userData.employeeNumber,
            birthday: props.userData.birthday,
            email: input.email,
            password: input.password,
          }),
          method: "POST",
          headers: { "content-type": "application/json" },
        }
      );
      if (registrationFetch.ok) {
        const emailConfirmationFetch = await fetch(
          process.env.REACT_APP_API_URL + "/api/auth/send-email-confirmation",
          {
            method: "POST",
            body: JSON.stringify({ email: input.email }),
            headers: { "content-type": "application/json" },
          }
        );
        if (emailConfirmationFetch.ok) {
          setSuccess(true);
          setIsLoading(false);
          return;
        }
        setError((await emailConfirmationFetch.json()).message);
        setIsLoading(false);
      }
      setError((await registrationFetch.json()).message);
    } catch (e: any) {
      setIsLoading(false);
      setError(e.message.toString());
    }
  };

  const handleUpdate = (update: Partial<RegistrationFormType>) => {
    setInput((prevState) => ({ ...prevState, ...update }));
  };

  return (
    <>
      {success ? (
        <>
          <p className="text-center mt-20">
            Wir haben eine E-Mail an ihre E-Mail Addresse geschickt. Bitte
            klicken Sie auf den Link, um die Registrierung abzuschließen.
          </p>
          <div className="mt-8 flex flex-col items-center">
            <Button
              color={ButtonColor.SECONDARY}
              link="/login"
              type="submit"
              className="mb-8 w-52"
            >
              Zum Login
            </Button>
          </div>
        </>
      ) : (
        <>
          <p className="text-center">
            Bitte geben Sie ihre Weihenstephaner E-Mail Addresse und ein
            Passwort ein, mit der Sie sich in Zukunft anmelden können.
          </p>
          <form className="mt-10" onSubmit={handleSubmit}>
            <input
              type="email"
              required
              className="ws-input mb-5"
              placeholder="E-Mail"
              value={input.email}
              onChange={(event) =>
                handleUpdate({
                  email: event.target.value,
                })
              }
            />
            <input
              type="password"
              required
              className="ws-input mb-5"
              placeholder="Passwort"
              minLength={6}
              value={input.password}
              onChange={(event) =>
                handleUpdate({
                  password: event.target.value,
                })
              }
            />
            <input
              type="password"
              required
              className="ws-input mb-5"
              placeholder="Passwort bestätigen"
              minLength={6}
              value={input.passwordConfirmation}
              onChange={(event) =>
                handleUpdate({
                  passwordConfirmation: event.target.value,
                })
              }
            />
            <div className="mt-4 flex flex-col items-center">
              {error && (
                <p className="mb-4 -mt-4 text-center text-copy-small text-danger">
                  {error}
                </p>
              )}
              <Button
                color={ButtonColor.PRIMARY}
                type="submit"
                disabled={isLoading}
                className={clsx([
                  "mb-8 w-52",
                  isLoading && "bg-dark-grey cursor-progress",
                ])}
              >
                Registrieren
                {isLoading && (
                  <div
                    className="inline-block ml-3 h-6 w-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-white"
                    role="status"
                    aria-label="loading"
                  />
                )}
              </Button>
            </div>
          </form>
          <div className="flex flex-col items-center">
            <Link to="/login" className="text-copy-small font-bold text-lg">
              Abbrechen
            </Link>
          </div>
        </>
      )}
    </>
  );
};
