interface TagProps {
  name: string;
}

export const Tag = (props: TagProps) => {
  return (
    <div className="w-max rounded-full bg-secondary py-1.5 px-2.5 text-preline font-bold uppercase text-white">
      {props.name}
    </div>
  );
};
