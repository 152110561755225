import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { Spinner } from "../components/other/Spinner/Spinner";
import { useFetchCollection, useFetchSingleData } from "../utils/fetchUtils";
import { NewsItemType } from "../models/NewsItem";
import { ArticleContent } from "../components/sections/ArticleContent/ArticleContent";
import { MoreNews } from "../components/sections/MoreNews/MoreNews";
import { useDarkTextColorToolbar } from "../utils/useDarkTextColorToolbar";
import { NotFoundPage } from "./NotFoundPage";

interface NewsDetailProps {
  slug?: string;
}

export const NewsDetail = (props: NewsDetailProps) => {
  useDarkTextColorToolbar();

  const [newsItem, newsItemLoading, error] = useFetchSingleData<NewsItemType>(
    {
      name: props.slug ? "news/" + props.slug : "",
      dataQuery: ["mainImage", "content.images", "content.file", "category"],
    },
    [props.slug]
  );
  const [additionalNews] = useFetchCollection<NewsItemType>(
    {
      name: "news",
      filter: [
        ...(newsItem?.attributes.category.data
          ? [
              {
                collectionType: "category",
                attribute: "slug",
                value: newsItem?.attributes.category.data.attributes.slug,
              },
              {
                collectionType: "id",
                attribute: "$ne",
                value: newsItem.id.toString(),
              },
            ]
          : []),
      ],
      pagination: { limit: 3, start: 0 },
      sort: ["releaseDate:DESC"],
      dataQuery: ["mainImage", "content.images", "category"],
    },
    [newsItem]
  );

  if (!props.slug || error) {
    return <NotFoundPage />;
  }

  return (
    <AuthenticatedPage hideHeader>
      {newsItemLoading || !newsItem ? (
        <Spinner />
      ) : (
        <div>
          <ArticleContent {...newsItem} />
          {additionalNews.length > 0 && <MoreNews items={additionalNews} />}
        </div>
      )}
    </AuthenticatedPage>
  );
};
