import { Calendar } from "../../other/Calendar/Calendar";
import { Button, ButtonColor } from "../../other/Button/Button";

export const CalendarTeaser = () => {
  return (
    <div className="mt-16">
      <h2 className="text-center font-headline text-h1">Kalender</h2>
      <div className="mt-8 flex justify-center">
        <Calendar />
      </div>
      <div className="mt-8 flex justify-center">
        <Button color={ButtonColor.INVERTED} link={"/events"} className="w-52">
          Alle Events sehen
        </Button>
      </div>
    </div>
  );
};
