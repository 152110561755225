import { Link } from "react-router-dom";
import { IonItem, IonRouterLink } from "@ionic/react";

interface HomeButtonProps {}

export const HomeButton = (props: HomeButtonProps) => {
  return (
    <IonRouterLink routerLink="/" routerDirection={"back"}>
      <div className="rounded-full shadow-alert bg-white flex justify-center items-center w-12 h-12">
        <i className="ri-home-4-fill text-secondary text-lg" />
      </div>
    </IonRouterLink>
  );
};
