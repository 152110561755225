import { PropsWithChildren, useContext } from "react";
import { UserContext } from "../../../utils/UserContext";
import { AuthenticatedFooter } from "./AuthenticatedFooter/AuthenticatedFooter";
import { useHideFooterOnKeyboardShow } from "../../../utils/useHideFooterOnKeyboardShow";
import clsx from "clsx";

export const AuthenticatedLayout = (props: PropsWithChildren<{}>) => {
  const { localUser } = useContext(UserContext);
  const showFooter = useHideFooterOnKeyboardShow();

  if (!localUser) {
    return null;
  }

  return (
    <>
      <div
        className={clsx([
          "w-full min-h-full pt-[5.5rem]",
          showFooter && "pb-[5.4375rem]",
        ])}
      >
        {props.children}
        {<AuthenticatedFooter showFooter={showFooter} />}
      </div>
    </>
  );
};
