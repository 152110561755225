import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import { useState } from "react";
import { IonRouterLink, isPlatform } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { AppLauncher } from "@capacitor/app-launcher";

interface AuthenticatedFooterProps {
  showFooter?: boolean;
}

export const AuthenticatedFooter = (props: AuthenticatedFooterProps) => {
  const history = useHistory();
  const [location, setLocation] = useState<string>(history.location.pathname);

  history.listen((location) => setLocation(location.pathname));

  const handleOpenChat = async () => {
    let urlToOpen = "mattermost://www.w1040.de/landing";
    if (isPlatform("android")) {
      urlToOpen = "de.weihenstephaner.mattermost.rn";
    }
    const { value } = await AppLauncher.canOpenUrl({
      url: urlToOpen,
    });
    if (value) {
      await AppLauncher.openUrl({ url: urlToOpen });
      return;
    }
    await Browser.open({ url: "https://www.w1040.de/landing" });
  };

  return (
    <footer
      className={clsx([
        "bottom-0 left-0 z-10 h-[6.25rem] pb-2 w-full bg-white",
        props.showFooter ? "fixed" : "hidden",
      ])}
    >
      <div className="relative flex h-full flex-col">
        <div
          className="h-2.5 w-full bg-repeat"
          style={{ backgroundImage: "url(/img/goldlinie_unten.png)" }}
        ></div>
        <div className="flex flex-1 items-center justify-between px-7">
          <IonRouterLink
            routerLink="/news"
            routerDirection={location.includes("/news/") ? "back" : "forward"}
          >
            <div
              className={clsx([
                "flex flex-col items-center text-preline font-bold text-primary",
                location.includes("/news") && "!text-secondary",
              ])}
            >
              <i className="ws-icons ws-icons-news mb-2 text-3xl font-normal" />
              NEWS
            </div>
          </IonRouterLink>
          <IonRouterLink
            routerLink="/events"
            routerDirection={location.includes("/events/") ? "back" : "forward"}
          >
            <div
              className={clsx([
                "flex flex-col items-center text-preline font-bold text-primary",
                location.includes("/events") && "!text-secondary",
              ])}
            >
              <i className="ws-icons ws-icons-kalender mb-2 text-3xl font-normal" />
              KALENDER
            </div>
          </IonRouterLink>
          <IonRouterLink
            routerLink="/knowledge"
            routerDirection={location.includes("/events/") ? "back" : "forward"}
          >
            <div
              className={clsx([
                "flex flex-col items-center text-preline font-bold text-primary",
                location.includes("/knowledge") && "!text-secondary",
              ])}
            >
              <i className="ws-icons ws-icons-wissen mb-2 text-3xl font-normal" />
              WISSEN
            </div>
          </IonRouterLink>
          <IonRouterLink
            routerLink="/internal"
            routerDirection={
              location.includes("/internal/") ? "back" : "forward"
            }
          >
            <div
              className={clsx([
                "flex flex-col items-center text-preline font-bold text-primary",
                location.includes("/internal") && "!text-secondary",
              ])}
            >
              <i className="ws-icons ws-icons-intern mb-2 text-3xl font-normal" />
              INTERN
            </div>
          </IonRouterLink>
        </div>
      </div>
      <button
        onClick={handleOpenChat}
        className="absolute -top-16 right-4 w-12 h-12 drop-shadow-md rounded-full flex justify-center items-center bg-primary"
      >
        <img
          className="w-6 h-6 object-contain"
          src="/img/chat-icon.svg"
          alt="chat icon"
        />
      </button>
    </footer>
  );
};
