import { MediaType } from "../../../models/Media";
import clsx from "clsx";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

interface ImageCardProps extends MediaType {
  className?: string;
  zoom?: boolean;
}

export const ImageCard = (props: ImageCardProps) => {
  return (
    <div
      className={clsx([
        "p-2.5 bg-white rounded-lg shadow-alert",
        props.className,
      ])}
    >
      {props.zoom ? (
        <TransformWrapper>
          <TransformComponent>
            <img
              src={`${process.env.REACT_APP_API_URL}${props.attributes.url}`}
              alt={props.attributes.alternativeText}
              className="w-full h-auto"
            />
          </TransformComponent>
        </TransformWrapper>
      ) : (
        <img
          src={`${process.env.REACT_APP_API_URL}${
            props.attributes.formats.medium
              ? props.attributes.formats.medium?.url
              : props.attributes.url
          }`}
          alt={props.attributes.alternativeText}
          className="w-full h-auto"
        />
      )}
    </div>
  );
};
