import { Spinner } from "../../other/Spinner/Spinner";
import { NewsCard } from "../../other/NewsCard/NewsCard";
import { Button, ButtonColor } from "../../other/Button/Button";
import { EventType } from "../../../models/Event";
import { EventCard } from "../../other/EventCard/EventCard";

interface EventsListProps {
  events: EventType[];
  loading: boolean;
  onLoadMoreButtonClick?: () => void;
}

export const EventsList = (props: EventsListProps) => {
  return (
    <div>
      {props.loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="grid grid-cols-1 gap-5 auto-rows-fr">
            {props.events.map((eventItem) => (
              <EventCard {...eventItem} key={eventItem.id} />
            ))}
          </div>
          {props.onLoadMoreButtonClick && (
            <div className="mt-8 flex justify-center">
              <Button
                color={ButtonColor.INVERTED}
                onClick={props.onLoadMoreButtonClick}
                className="w-52"
              >
                Mehr anzeigen
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
