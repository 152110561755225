import { Tag } from "../../Tag/Tag";
import dayjs from "dayjs";
import { EventType } from "../../../../models/Event";
import { useHistory } from "react-router-dom";

interface CalendarDetailProps {
  activeEvent: EventType;
  onArrowClick: (direction: "next" | "back") => void;
}

export const CalendarDetail = (props: CalendarDetailProps) => {
  const history = useHistory();

  const handleEventClick = () => {
    history.push(`/events/${props.activeEvent.attributes.slug}`);
  };
  return (
    <div className="-mt-6 bg-primary py-4 px-2.5 rounded-br-lg rounded-bl-lg max-w-[21.875rem]">
      <div className="pt-5 border-t-2 border-primary-darker flex items-center">
        <i
          className="ri-arrow-left-s-line text-white text-lg px-2"
          onClick={() => props.onArrowClick("back")}
        />
        <div className="flex-1 text-white space-y-3" onClick={handleEventClick}>
          {props.activeEvent.attributes.category.data && (
            <Tag
              name={props.activeEvent.attributes.category.data.attributes.name}
            />
          )}
          <h2 className="font-headline text-h3 px-2.5">
            {props.activeEvent.attributes.title}
          </h2>
          <p className="px-2.5 pb-1">
            {dayjs(props.activeEvent.attributes.date).format("DD.MM.YYYY")}
          </p>
        </div>
        <i
          className="ri-arrow-right-s-line text-white text-lg px-2"
          onClick={() => props.onArrowClick("next")}
        />
      </div>
    </div>
  );
};
