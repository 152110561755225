import clsx from "clsx";

interface CategoryFilterProps {
  categories: { label: string; value: string }[];
  categoryFilter: string;
  setCategoryFilter: (slug: string) => void;
  allLabel?: string;
}

export const CategoryFilter = (props: CategoryFilterProps) => {
  const filters = [
    { label: props.allLabel ?? "Alle", value: "" },
    ...props.categories,
  ];

  const handleCategoryClick = (category: string) => {
    props.setCategoryFilter(category);
  };
  return (
    <section className="relative z-10 bg-white shadow-banner">
      <div
        className="h-2.5 w-full bg-full"
        style={{
          backgroundImage: "url('/img/goldlinie_oben.png')",
        }}
      />
      <div className="no-scrollbar max-w-full overflow-x-auto">
        <div className="flex">
          <ul className="flex space-x-8 justify-around font-bold uppercase text-primary py-5 px-8 mx-auto min-w-min">
            {filters.map((category) => (
              <li
                className={clsx([
                  props.categoryFilter === category.value && "text-secondary",
                  "cursor-pointer transition-colors duration-300 hover:text-secondary w-max",
                ])}
                onClick={() => handleCategoryClick(category.value)}
                key={category.value}
              >
                {category.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="h-2.5 w-full bg-full"
        style={{
          backgroundImage: "url('/img/goldlinie_unten.png')",
        }}
      />
    </section>
  );
};
