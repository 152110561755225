import { DefaultLayout } from "../components/layouts/DefaultLayout/DefaultLayout";
import React, { useEffect, useState } from "react";
import { Button, ButtonColor } from "../components/other/Button/Button";

export const PasswordReset = () => {
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    setCode(new URLSearchParams(window.location.search).get("code") ?? "");
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== passwordConfirmation) {
      setError("Die Passwörter stimmen nicht überein.");
      return;
    }
    try {
      const resetFetch = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/reset-password`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({ code, password, passwordConfirmation }),
        }
      );
      if (resetFetch.ok) {
        setError("");
        setSuccess(true);
      }
      const message = await resetFetch.json();
      if (message.error?.message === "Incorrect code provided") {
        setError("Der Code ist abgelaufen. Fordern Sie ein neues Passwort an.");
      }
    } catch (e: any) {
      console.log(e);
      setError(e.toString());
    }
  };
  return (
    <DefaultLayout>
      <div className="flex flex-1 justify-center bg-beige py-20 pt-24">
        <div className="max-w-[17.5rem]">
          <h1 className="mb-6 text-center font-headline text-h2">
            Passwort
            <br />
            Zurücksetzen
          </h1>
          {!code ? (
            <p className="text-center">
              Der Link ist ungültig. Bitte folgen Sie dem Link aus der E-Mail
            </p>
          ) : success ? (
            <>
              <p className="text-center mt-20">
                Das Passwort wurde erfolgreich zurückgesetzt. Bitte loggen Sie
                sich erneut ein.
              </p>
              <div className="mt-8 flex flex-col items-center">
                <Button
                  color={ButtonColor.SECONDARY}
                  link="/login"
                  type="submit"
                  className="mb-8 w-52"
                >
                  Zum Login
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="text-center">Geben Sie ein neues Passwort ein.</p>
              <form className="mt-10" onSubmit={handleSubmit}>
                <input
                  type="password"
                  className="ws-input mb-5"
                  required
                  name="password"
                  id="password"
                  placeholder="Neues Passwort"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <input
                  type="password"
                  className="ws-input mb-5"
                  required
                  name="password-confirmation"
                  id="password-confirmation"
                  placeholder="Passwort bestätigen"
                  value={passwordConfirmation}
                  onChange={(event) =>
                    setPasswordConfirmation(event.target.value)
                  }
                />
                <div className="mt-4 flex flex-col items-center">
                  {error && (
                    <p className="mb-4 -mt-4 max-w-[10rem] text-center text-copy-small text-danger">
                      {error}
                    </p>
                  )}
                  <Button
                    color={ButtonColor.PRIMARY}
                    type="submit"
                    className="mb-8 w-52"
                  >
                    Absenden
                  </Button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};
