import { IonContent, IonPage } from "@ionic/react";
import { PropsWithChildren } from "react";
import clsx from "clsx";
import { Header } from "../Header/Header";

interface AuthenticatedPageProps {
  hideHeader?: boolean;
}

export const AuthenticatedPage = (
  props: PropsWithChildren<AuthenticatedPageProps>
) => {
  return (
    <IonPage>
      {!props.hideHeader && <Header showAuthenticatedVersion />}
      <IonContent fullscreen>
        <div
          className={clsx([
            "bg-beige min-h-full pb-[5.4375rem]",
            !props.hideHeader && "native-header-pt",
          ])}
        >
          {props.children}
        </div>
      </IonContent>
    </IonPage>
  );
};
