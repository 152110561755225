import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Markdown } from "../../other/Markdown/Markdown";
import { Button, ButtonColor } from "../../other/Button/Button";

interface AccordionItem {
  name: string;
  text: string;
  link?: string;
}

interface AccordionProps {
  classNames?: string;
  items: AccordionItem[];
  addCardToImage?: boolean;
}

export const Accordion = (props: AccordionProps) => {
  const [openItemIndex, setOpenItemIndex] = useState<number | null>(null);
  const itemsRef = useRef<Array<HTMLParagraphElement | null>>([]);

  useEffect(() => {
    setOpenItemIndex(null);
  }, [props.items]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, props.items.length);
  }, [props.items]);

  const toggleOpenState = (index: number) => {
    if (openItemIndex === index) {
      setOpenItemIndex(null);
    } else {
      setOpenItemIndex(index);
    }
  };
  return (
    <div
      className={clsx([
        "divide-y border-border-color",
        props.items.length > 0 && "border-t border-b",
        props.classNames,
      ])}
    >
      {props.items.map((item, index) => (
        <div
          key={index}
          className="cursor-pointer py-5"
          onClick={() => toggleOpenState(index)}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h3 className="font-headline text-h3">{item.name}</h3>
            </div>
            {openItemIndex === index ? (
              <i className="ri-subtract-line text-xl leading-none text-secondary" />
            ) : (
              <i className="ri-add-line text-xl leading-none text-secondary" />
            )}
          </div>
          <div
            className={clsx([
              "overflow-hidden transition-all duration-500",
              openItemIndex === index ? "mt-5 opacity-100" : "opacity-0",
            ])}
            style={{
              height:
                openItemIndex === index
                  ? itemsRef.current[index]?.scrollHeight + "px"
                  : 0,
            }}
            ref={(el) => (itemsRef.current[index] = el)}
          >
            <Markdown>{item.text}</Markdown>
            {item.link && (
              <Button
                color={ButtonColor.INVERTED}
                link={item.link}
                className="mt-8 w-52"
              >
                {item.link.startsWith("/")
                  ? "Mehr dazu"
                  : "Jetzt herunterladen"}
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
