import { PropsWithChildren } from "react";
import clsx from "clsx";

interface MarkdownProps {
  className?: string;
}

export const Markdown = (props: PropsWithChildren<MarkdownProps>) => {
  if (typeof props.children !== "string") return null;
  return (
    <div
      className={clsx(["markdown", props.className])}
      dangerouslySetInnerHTML={{ __html: props.children }}
    />
  );
};
