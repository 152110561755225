import clsx from "clsx";

interface DefaultFooterProps {
  showFooter?: boolean;
}

export const DefaultFooter = (props: DefaultFooterProps) => {
  return (
    <footer
      className={clsx([
        "h-24 w-full bg-primary text-white bottom-0 left-0",
        props.showFooter ? "fixed" : "hidden",
      ])}
    >
      <div
        className="h-2.5 w-full bg-repeat"
        style={{ backgroundImage: "url(/img/goldlinie_unten.png)" }}
      ></div>
    </footer>
  );
};
