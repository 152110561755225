import { NewsItemType } from "../../../models/NewsItem";
import { Tag } from "../Tag/Tag";
import { Link } from "react-router-dom";

interface NewsCardProps extends NewsItemType {
  showTeaser?: boolean;
}

export const NewsCard = (props: NewsCardProps) => {
  return (
    <Link
      to={`/news/${props.attributes.slug}`}
      className="flex flex-col justify-between space-y-2.5 rounded-lg bg-brown p-2.5 text-white"
    >
      {props.attributes.category.data && (
        <Tag name={props.attributes.category.data.attributes.name} />
      )}
      <h2 className="font-headline text-h3 px-2.5">
        {props.attributes.headline}
      </h2>
      <p className="px-2.5">
        {props.showTeaser
          ? props.attributes.teaser
          : getReadingTime(props.attributes.teaser, props.attributes.content) +
            " min Lesezeit"}
      </p>
    </Link>
  );
};

export const getReadingTime = (
  teaser: string | undefined,
  content?: NewsItemType["attributes"]["content"]
) => {
  let wordCount = 0;
  if (teaser) {
    wordCount += teaser.split(" ").length;
  }
  content?.forEach((item) => {
    if ("text" in item && item.text) {
      wordCount += item.text.split(" ").length;
    }
  });
  const min = Math.round(wordCount / 250);
  return min < 1 ? 1 : min;
};
