import { useHistory } from "react-router-dom";

export const ArticleFooter = () => {
  const history = useHistory();

  return (
    <>
      <div className="mt-15 border-t mb-8 border-gray-300" />
      <div className="flex justify-between items-center">
        <div
          className="cursor-pointer flex items-center uppercase text-preline font-bold tracking-wider"
          onClick={() => history.go(-1)}
        >
          <i className="ws-icons ws-icons-pfeil_back mr-1 text-lg" />
          Zurück
        </div>
      </div>
    </>
  );
};
