import React from "react";
import clsx from "clsx";

interface SpinnerProps {
  className?: string;
}

export const Spinner = (props: SpinnerProps) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        className={clsx([
          "inline-block h-6 w-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600",
          props.className,
        ])}
        role="status"
        aria-label="loading"
      />
    </div>
  );
};
