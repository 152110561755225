import { useFetchSingleData } from "../utils/fetchUtils";
import { EventType } from "../models/Event";
import { InternalNewsItemType } from "../models/InternalNewsItem";
import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { Spinner } from "../components/other/Spinner/Spinner";
import { ArticleContent } from "../components/sections/ArticleContent/ArticleContent";
import { useDarkTextColorToolbar } from "../utils/useDarkTextColorToolbar";
import { useContext } from "react";
import { UserContext } from "../utils/UserContext";
import { NotFoundPage } from "./NotFoundPage";

interface InternalDetailProps {
  slug?: string;
}

export const InternalDetail = (props: InternalDetailProps) => {
  useDarkTextColorToolbar();
  const { localUser } = useContext(UserContext);

  const [newsItem, itemLoading, error] =
    useFetchSingleData<InternalNewsItemType>(
      {
        name: props.slug ? "internal-news/" + props.slug : "",
        dataQuery: ["download", "category", "content.images", "content.file"],
      },
      [props.slug]
    );
  if (!props.slug || error) {
    return <NotFoundPage />;
  }

  const userCanCheckItem =
    newsItem?.attributes.checkConfirmation &&
    !localUser?.readInternalNews.map((news) => news.id).includes(newsItem.id);

  const checkConfirmation = async () => {
    if (localUser && newsItem) {
      const alreadyReadInternalNews = localUser.readInternalNews.map(
        (item) => item.id
      );
      const updateFetch = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/${localUser?.id}`,
        {
          headers: new Headers({
            "content-type": "application/json",
            Authorization: `Bearer ${localUser?.jwt}`,
          }),
          method: "PUT",
          body: JSON.stringify({
            readInternalNews: [...alreadyReadInternalNews, newsItem.id],
          }),
        }
      );
      if (updateFetch.ok) {
        window.location.reload();
      }
    }
  };

  return (
    <AuthenticatedPage hideHeader>
      {itemLoading || !newsItem ? (
        <Spinner />
      ) : (
        <div>
          <ArticleContent
            id={newsItem.id}
            attributes={{
              teaser: newsItem.attributes.teaser,
              releaseDate: newsItem.attributes.releaseDate,
              content: newsItem.attributes.content,
              headline: newsItem.attributes.title,
              slug: newsItem.attributes.slug,
              mainImage: { data: null } as any,
              category: newsItem.attributes.category,
            }}
            checkConfirmation={userCanCheckItem ? checkConfirmation : undefined}
          />
        </div>
      )}
    </AuthenticatedPage>
  );
};
