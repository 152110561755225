import { FetchDataInterface } from "../fetchTypes";
import dayjs from "dayjs";

export const latestEventsInterface: FetchDataInterface = {
  name: "events",
  dataQuery: ["mainImage", "content.images", "category"],
  filter: [
    {
      attribute: "date",
      value: { $lte: dayjs().add(2, "months").format("YYYY-MM-DD") },
    },
    {
      attribute: "date",
      value: { $gte: dayjs().subtract(2, "months").format("YYYY-MM-DD") },
    },
  ],
  sort: ["date:DESC"],
};

export const eventOverviewInterface: FetchDataInterface = {
  name: "events",
  dataQuery: ["category"],
  sort: ["date:DESC"],
};
