import ReactDOM from "react-dom";

interface DeletionModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

export const DeletionModal = (props: DeletionModalProps) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 transition-all bg-white bg-opacity-60 flex justify-center items-center p-4 z-50 lg:px-48">
      <div className="bg-primary rounded-lg px-4 py-20 text-white w-full relative text-white">
        <i
          className="ri-close-line absolute right-3 top-3 text-secondary text-lg h-4 flex items-center"
          onClick={props.onClose}
        />
        <h1 className="text-h2 font-bold text-center">
          Sind Sie sich sicher, dass der Account gelöscht werden soll?
        </h1>
        <div className="flex justify-center items-center mt-16 flex-col gap-4">
          <button
            className="text-white bg-red-700 rounded-full p-4 uppercase font-bold"
            onClick={props.onSubmit}
          >
            Ja löschen
          </button>
          <button
            className="text-white bg-transparent rounded-full p-4 uppercase font-bold"
            onClick={props.onClose}
          >
            Nein Account behalten
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};
