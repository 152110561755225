import clsx from "clsx";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Browser } from "@capacitor/browser";

interface ButtonProps {
  link?: string;
  color: ButtonColor;
  className?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: () => void;
  disabled?: boolean;
}

export enum ButtonColor {
  "PRIMARY" = "PRIMARY",
  "SECONDARY" = "SECONDARY",
  "INVERTED" = "INVERTED",
  "SUCCESS" = "SUCCESS",
  "DANGER" = "DANGER",
}

export const Button = (props: PropsWithChildren<ButtonProps>) => {
  const bgColors = () => {
    switch (props.color) {
      case ButtonColor.PRIMARY:
        return "bg-primary text-white hover-device:bg-primary-darker";
      case ButtonColor.SECONDARY:
        return "bg-secondary text-white hover-device:hover:bg-secondary-lighter";
      case ButtonColor.INVERTED:
        return (
          "bg-transparent !border-brown hover-device:hover:bg-brown " +
          "hover-device:hover:text-white hover-device:hover:border-transparent"
        );
      case ButtonColor.SUCCESS:
        return "bg-success hover-device:hover:bg-success-darker text-white";
      case ButtonColor.DANGER:
        return "bg-danger hover-device:hover:bg-danger-darker text-white";
    }
  };

  const classNames = clsx([
    "min-w-50 rounded-full border-2 border-transparent justify-center " +
      "font-bold text-button transition-colors duration-300 p-4 uppercase " +
      "inline-flex items-center",
    bgColors(),
    props.className,
  ]);

  if (props.link?.startsWith("/")) {
    return (
      <Link to={props.link} className={classNames}>
        {props.children}
      </Link>
    );
  }
  if (props.link) {
    return (
      <button
        className={classNames}
        disabled={props.disabled}
        onClick={() => Browser.open({ url: props.link ?? "" })}
      >
        {props.children}
      </button>
    );
  }
  return (
    <button
      className={classNames}
      disabled={props.disabled}
      type={props.type}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
