import { ImportantAlert } from "../components/sections/ImportantAlert/ImportantAlert";
import { useFetchCollection, useFetchSingleData } from "../utils/fetchUtils";
import { HomeScreenType } from "../models/HomeScreen";
import { KnowledgeTeaser } from "../components/sections/KnowledgeTeaser/KnowledgeTeaser";
import { NewsItemType } from "../models/NewsItem";
import { heroNewsInterface } from "../utils/fetchData/fetchNews";
import { NewsTeaser } from "../components/sections/NewsTeaser/NewsTeaser";
import { CalendarTeaser } from "../components/sections/CalendarTeaser/CalendarTeaser";
import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { useDarkTextColorToolbar } from "../utils/useDarkTextColorToolbar";
import clsx from "clsx";
import { useState } from "react";
import { useIonViewWillEnter } from "@ionic/react";

const Home = () => {
  useDarkTextColorToolbar();
  const [indexIsFocusedIndex, setIndexIsFocusedIndex] = useState<number>(0);
  useIonViewWillEnter(() => {
    setIndexIsFocusedIndex((prevState) => prevState + 1);
  }, []);

  const [latestNews, newsLoading] = useFetchCollection<NewsItemType>(
    heroNewsInterface,
    [indexIsFocusedIndex]
  );

  const [homeScreenData, homeScreenLoading] =
    useFetchSingleData<HomeScreenType>(
      {
        name: "home-screen",
        dataQuery: ["knowledgeItem", "importantNotification"],
      },
      [indexIsFocusedIndex]
    );

  return (
    <AuthenticatedPage>
      {homeScreenData?.attributes.importantNotification.data && (
        <ImportantAlert
          {...homeScreenData.attributes.importantNotification.data}
        />
      )}
      <div
        className={clsx([
          "container py-14",
          !homeScreenData?.attributes.importantNotification.data && "pt-28",
        ])}
      >
        <NewsTeaser news={latestNews} isLoading={newsLoading} />
        <CalendarTeaser />
        {homeScreenData?.attributes.knowledgeItem.data && (
          <KnowledgeTeaser
            item={homeScreenData.attributes.knowledgeItem.data}
          />
        )}
      </div>
    </AuthenticatedPage>
  );
};

export default Home;
