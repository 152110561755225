import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SESSION_COOKIE_NAME } from "../utils/constants";
import { Preferences } from "@capacitor/preferences";
import { UserContext } from "../utils/UserContext";
import { Button, ButtonColor } from "../components/other/Button/Button";
import { Link, useHistory } from "react-router-dom";
import { DefaultLayout } from "../components/layouts/DefaultLayout/DefaultLayout";
import { Browser } from "@capacitor/browser";

dayjs.extend(utc);

const LoginPage = () => {
  const history = useHistory();
  const { localUser } = useContext(UserContext);
  const [loginFormData, setLoginFormData] = useState<{
    name: string;
    password: string;
  }>({ name: "", password: "" });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (!!localUser) {
      history.push("/");
    }
  }, [localUser]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const loginFetch = await fetch(
        process.env.REACT_APP_API_URL + "/api/auth/local",
        {
          body: JSON.stringify({
            identifier: loginFormData.name,
            password: loginFormData.password,
          }),
          method: "POST",
          headers: { "content-type": "application/json" },
        }
      );
      if (loginFetch.ok) {
        const result = await loginFetch.json();
        if (result.user.confirmed) {
          await Preferences.set({
            key: SESSION_COOKIE_NAME,
            value: JSON.stringify({
              jwt: result.jwt,
              expires: dayjs().add(30, "day").utc().toISOString(),
            }),
          });
          window.location.reload();
        } else {
          setError("Bitte bestätigen Sie ihre E-Mail Addresse.");
        }
      } else if (loginFetch.status === 400) {
        setError("Nutzername und Passwort stimmen nicht überein");
      }
    } catch (e: any) {
      setError(
        "Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut"
      );
    }
  };
  return (
    <DefaultLayout>
      <div className="flex flex-1 justify-center bg-beige py-20 pt-24">
        <div className="max-w-[17.5rem]">
          <h1 className="mb-6 text-center font-headline text-h2">
            Mitarbeiter
            <br />
            Login
          </h1>
          <p className="text-center">
            Herzlich willkommen bei der Bayerischen Staatsbrauerei
            Weihenstephan.
          </p>
          <form className="mt-10" onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              className="ws-input mb-5"
              placeholder="E-Mail"
              value={loginFormData.name}
              onChange={(event) =>
                setLoginFormData((prevState) => ({
                  ...prevState,
                  name: event.target.value,
                }))
              }
            />
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="ws-input pr-12"
                placeholder="Passwort"
                value={loginFormData.password}
                onChange={(event) =>
                  setLoginFormData((prevState) => ({
                    ...prevState,
                    password: event.target.value,
                  }))
                }
              />
              <i
                className="ws-icons ws-icons-auge absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer text-secondary"
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            </div>
            <div className="mt-8 flex flex-col items-center">
              {error && (
                <p className="mb-4 -mt-4 max-w-[10rem] text-center text-copy-small text-danger">
                  {error}
                </p>
              )}
              <Button
                color={ButtonColor.PRIMARY}
                type="submit"
                className="mb-8 w-52"
              >
                Absenden
              </Button>
              <Link
                to="/forgot-password"
                className="mb-4 text-copy-small font-bold text-secondary text-lg"
              >
                Passwort vergessen?
              </Link>
              <Link
                to="/registration"
                className="text-copy-small font-bold text-lg mb-4"
              >
                Registrieren
              </Link>
              <button
                className="text-copy-small font-bold text-lg"
                type="button"
                onClick={() =>
                  Browser.open({
                    url: "https://www.weihenstephaner.de/datenschutz" ?? "",
                  })
                }
              >
                Datenschutz
              </button>
            </div>
          </form>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default LoginPage;
