import { PropsWithChildren, useEffect, useState } from "react";
import { DefaultFooter } from "./DefaultFooter/DefaultFooter";
import { Header } from "../Header/Header";
import { IonContent, IonPage } from "@ionic/react";
import { useHideFooterOnKeyboardShow } from "../../../utils/useHideFooterOnKeyboardShow";
import clsx from "clsx";

export const DefaultLayout = (props: PropsWithChildren) => {
  const showFooter = useHideFooterOnKeyboardShow();

  return (
    <IonPage className="">
      <Header />
      <IonContent>
        <div className="relative">
          <div
            className={clsx([
              "w-full min-h-screen flex flex-col pt-[5.5rem]",
              showFooter && "pb-[5.4375rem]",
            ])}
          >
            {props.children}
          </div>
          <DefaultFooter showFooter={showFooter} />
        </div>
      </IonContent>
    </IonPage>
  );
};
