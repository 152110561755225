import { EventType } from "../../../models/Event";
import { Link } from "react-router-dom";
import { Tag } from "../Tag/Tag";
import dayjs from "dayjs";
import clsx from "clsx";

export const EventCard = (props: EventType) => {
  return (
    <Link
      to={`/events/${props.attributes.slug}`}
      className={clsx([
        "flex flex-col justify-between space-y-2.5 rounded-lg bg-primary p-2.5 text-white",
        !props.attributes.category.data && "pt-5",
      ])}
    >
      {props.attributes.category.data && (
        <Tag name={props.attributes.category.data.attributes.name} />
      )}
      <h2 className="font-headline text-h3 px-2.5">{props.attributes.title}</h2>
      <p className="px-2.5 pb-1">
        {dayjs(props.attributes.date).format("DD.MM.YYYY")}
      </p>
    </Link>
  );
};
