import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { BackButton } from "../components/other/BackButton/BackButton";
import React, { useContext, useMemo, useState } from "react";
import { User } from "../models/User";
import { UserContext } from "../utils/UserContext";
import { Button, ButtonColor } from "../components/other/Button/Button";
import { useIonViewWillLeave } from "@ionic/react";
import { Preferences } from "@capacitor/preferences";
import { SESSION_COOKIE_NAME } from "../utils/constants";
import { useDarkTextColorToolbar } from "../utils/useDarkTextColorToolbar";
import dayjs from "dayjs";
import { DeletionModal } from "../components/DeletionModal/DeletionModal";
import { Browser } from "@capacitor/browser";

export const ProfilePage = () => {
  useDarkTextColorToolbar();

  const { localUser } = useContext(UserContext);
  const [inputData, setInputData] = useState<Partial<User>>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [deletionModalIsOpen, setDeletionModalIsOpen] = useState(false);

  useIonViewWillLeave(() => {
    setInputData({});
  }, []);

  const derivedProfile = useMemo(() => {
    let birthday: dayjs.Dayjs | string | null = null;
    if (localUser && localUser.birthday) {
      const originalBirthday = dayjs(localUser.birthday, "DD.MM.YYYY");
      if (originalBirthday?.isValid()) {
        birthday = originalBirthday?.format("YYYY-MM-DD");
      } else {
        birthday = localUser.birthday;
      }
    }
    return { ...localUser, birthday, ...inputData };
  }, [inputData, localUser]);

  const logout = async () => {
    await Preferences.remove({ key: SESSION_COOKIE_NAME });
    window.location.reload();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const updateFetch = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${localUser?.id}`,
      {
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${localUser?.jwt}`,
        }),
        method: "PUT",
        body: JSON.stringify(inputData),
      }
    );
    if (updateFetch.ok) {
      window.location.reload();
    }
  };

  const handleAccountDeletion = async () => {
    const updateFetch = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${localUser?.id}`,
      {
        headers: new Headers({
          "content-type": "application/json",
          Authorization: `Bearer ${localUser?.jwt}`,
        }),
        method: "DELETE",
      }
    );
    if (updateFetch.ok) {
      window.location.reload();
    }
  };

  return (
    <AuthenticatedPage hideHeader>
      <div className="bg-beige native-pt">
        <div className="fixed top-0 left-0 z-50 native-pt w-full bg-beige" />
        <div className="container pt-5 pb-16">
          <BackButton className="mb-10" />
          <h1 className="text-h2 font-headline text-center mb-8">Profil</h1>
          <div className="mb-8 text-center">
            <span className="font-bold mr-2">Personal-Nr.</span>
            {localUser?.employeeNumber}
          </div>
          <form onSubmit={handleSubmit} className="space-y-5">
            <div className="relative">
              <input
                type="text"
                className="ws-input pr-12"
                placeholder="Vorname"
                value={derivedProfile.firstName ?? ""}
                onChange={(event) =>
                  setInputData((prevState) => ({
                    ...prevState,
                    firstName: event.target.value,
                  }))
                }
              />
              <i className="ws-icons ws-icons-edit pointer-events-none absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer text-secondary" />
            </div>
            <div className="relative">
              <input
                type="text"
                className="ws-input pr-12"
                placeholder="Nachname"
                value={derivedProfile.lastName ?? ""}
                onChange={(event) =>
                  setInputData((prevState) => ({
                    ...prevState,
                    lastName: event.target.value,
                  }))
                }
              />
              <i className="ws-icons ws-icons-edit pointer-events-none absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer text-secondary" />
            </div>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="ws-input pr-12"
                placeholder="Passwort ändern"
                value={derivedProfile.password ?? ""}
                onChange={(event) =>
                  setInputData((prevState) => ({
                    ...prevState,
                    password: event.target.value,
                  }))
                }
              />
              <i
                className="ws-icons ws-icons-auge absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer text-secondary"
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            </div>
            <div className="relative">
              <input
                type={"date"}
                className="ws-input !min-h-[3.125rem] !w-full !pr-12 !px-4 !px-5 !text-left"
                placeholder="Geburtsdatum"
                value={derivedProfile.birthday ?? ""}
                onChange={(event) =>
                  setInputData((prevState) => ({
                    ...prevState,
                    birthday: event.target.value,
                  }))
                }
              />
              {!derivedProfile.birthday && (
                <div className="pointer-events-none absolute top-[17px] text-brown left-5 bg-white text-opacity-50 peer-focus-within:hidden">
                  Geburtsdatum
                </div>
              )}
              <i className="ri-calendar-line text-2xl pointer-events-none absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer text-secondary" />
            </div>
            <div className="flex items-center flex-col !mt-10">
              <Button color={ButtonColor.PRIMARY} type="submit">
                Änderungen speichern
              </Button>
              <button className="mt-6 font-bold" onClick={logout}>
                Abmelden
              </button>
              <button
                className="mt-6 font-bold"
                type="button"
                onClick={() => setDeletionModalIsOpen(true)}
              >
                Account löschen
              </button>
              <button
                className="mt-6 font-bold"
                type="button"
                onClick={() =>
                  Browser.open({
                    url: "https://www.weihenstephaner.de/datenschutz" ?? "",
                  })
                }
              >
                Datenschutz
              </button>
            </div>
          </form>
        </div>
      </div>
      {deletionModalIsOpen && (
        <DeletionModal
          onClose={() => setDeletionModalIsOpen(false)}
          onSubmit={handleAccountDeletion}
        />
      )}
    </AuthenticatedPage>
  );
};
