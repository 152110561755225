import clsx from "clsx";
import { IonHeader, IonRouterLink } from "@ionic/react";
import { Notifications } from "./Notifications/Notifications";

interface HeaderProps {
  showAuthenticatedVersion?: boolean;
}

export const Header = (props: HeaderProps) => {
  return (
    <IonHeader className={clsx(["fixed top-0 left-0 z-20"])}>
      <div
        className={clsx([
          "relative flex w-full native-header",
          props.showAuthenticatedVersion ? "bg-beige" : "bg-primary",
          props.showAuthenticatedVersion
            ? "flex-col justify-between"
            : "items-end",
        ])}
      >
        {props.showAuthenticatedVersion && (
          <>
            <div />
            <div className="container">
              <div className="-mx-2 flex items-center justify-between text-xl text-secondary">
                <Notifications />
                <IonRouterLink routerLink="/profile" className="p-2">
                  <div className="flex text-secondary">
                    <i className="ws-icons ws-icons-profil" />
                  </div>
                </IonRouterLink>
              </div>
            </div>
          </>
        )}
        <div
          className="h-2.5 w-full bg-repeat"
          style={{ backgroundImage: "url(/img/goldlinie_oben.png)" }}
        ></div>
        <IonRouterLink
          routerLink="/"
          className="absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
        >
          <img
            src="/img/logo.png"
            alt="ws-logo"
            className="h-[7.5rem] w-auto"
          />
        </IonRouterLink>
      </div>
    </IonHeader>
  );
};
