import { DefaultLayout } from "../components/layouts/DefaultLayout/DefaultLayout";
import React, { useState } from "react";
import {
  FindUserByBaseData,
  UserDataType,
} from "../components/sections/FindUserByBaseData/FindUserByBaseData";
import { RegistrationForm } from "../components/sections/RegistrationForm/RegistrationForm";

export const Registration = () => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userIdForRegistration, setUserIdForRegistration] = useState<number>(0);
  const [input, setInput] = useState<UserDataType>({
    firstName: "",
    lastName: "",
    employeeNumber: "",
    birthday: "",
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const getUserFetch = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/findUserByInternalData`,
        {
          headers: { "content-type": "application/json" },
          method: "POST",
          body: JSON.stringify({
            firstName: input.firstName,
            lastName: input.lastName,
            employeeNumber: input.employeeNumber,
            birthday: input.birthday,
          }),
        }
      );
      const returnData = await getUserFetch.json();
      if (getUserFetch.ok) {
        setUserIdForRegistration(returnData.id);
      }
      if (getUserFetch.status === 406) {
        setError("Der Benutzer ist bereits registriert.");
      }
      if (getUserFetch.status === 404) {
        setError(
          "Der Benutzer wurde nicht gefunden. Bitte wenden Sie sich an den Administrator, um freigeschaltet zu werden."
        );
      }
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setError(e.toString());
    }
  };

  const handleUpdate = (update: Partial<UserDataType>) => {
    setInput((prevState) => ({ ...prevState, ...update }));
  };
  return (
    <DefaultLayout>
      <div className="flex flex-1 justify-center bg-beige py-20 pt-24">
        <div className="max-w-[17.5rem]">
          <h1 className="mb-6 text-center font-headline text-h2">
            Mitarbeiter
            <br />
            Registrierung
          </h1>
          {userIdForRegistration ? (
            <RegistrationForm userId={userIdForRegistration} userData={input} />
          ) : (
            <FindUserByBaseData
              input={input}
              onUpdate={handleUpdate}
              error={error}
              isLoading={isLoading}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};
