import { NewsItemType } from "../../../models/NewsItem";
import { Spinner } from "../../other/Spinner/Spinner";
import { NewsCard } from "../../other/NewsCard/NewsCard";
import { Button, ButtonColor } from "../../other/Button/Button";

interface NewsListProps {
  news: NewsItemType[];
  loading: boolean;
  onLoadMoreButtonClick?: () => void;
}

export const NewsList = (props: NewsListProps) => {
  return (
    <div className="container py-14">
      {props.loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="grid grid-cols-1 gap-5 auto-rows-fr">
            {props.news.map((newsItem) => (
              <NewsCard {...newsItem} key={newsItem.id} />
            ))}
          </div>
          {props.onLoadMoreButtonClick && (
            <div className="mt-8 flex justify-center">
              <Button
                color={ButtonColor.INVERTED}
                onClick={props.onLoadMoreButtonClick}
                className="w-52"
              >
                Mehr anzeigen
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
