import { FetchDataInterface } from "../fetchTypes";

export const allNewsCategoriesInterface: FetchDataInterface = {
  name: "news-categories",
};

export const allEventsCategoriesInterface: FetchDataInterface = {
  name: "events-categories",
};

export const allKnowledgeCategoriesInterface: FetchDataInterface = {
  name: "knowledge-categories",
};

export const allInternalCategoriesInterface: FetchDataInterface = {
  name: "internal-news-categories",
};
