import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { useFetchCollection } from "../utils/fetchUtils";
import { InternalNewsItemType } from "../models/InternalNewsItem";
import { internalNewsOverviewInterface } from "../utils/fetchData/fetchInternalNews";
import { EventsCategoryType } from "../models/EventsCategory";
import { allInternalCategoriesInterface } from "../utils/fetchData/fetchCategories";
import { CategoryFilter } from "../components/sections/CategoryFilter/CategoryFilter";
import React, { useEffect, useState } from "react";
import { useLazyInput } from "../utils/useLazyInput";
import { Accordion } from "../components/sections/Accordion/Accordion";
import { Button, ButtonColor } from "../components/other/Button/Button";
import { HomeButton } from "../components/other/HomeButton/HomeButton";
import { DownloadType } from "../models/components/Download";

export const InternalPage = () => {
  const pageSize = 10;
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  const [searchInput, setSearchInput, lastSearchFetch] = useLazyInput();
  const [limit, setLimit] = useState<number>(pageSize);

  useEffect(() => {
    setLimit(pageSize);
  }, [categoryFilter]);

  const [internalNews, internalNewsLoading, newsMeta] =
    useFetchCollection<InternalNewsItemType>(
      {
        ...internalNewsOverviewInterface,
        filter: [
          ...(lastSearchFetch
            ? [
                {
                  collectionType: "title",
                  attribute: "$containsi",
                  value: lastSearchFetch,
                },
              ]
            : []),
          ...(categoryFilter
            ? [
                {
                  collectionType: "category",
                  attribute: "slug",
                  value: categoryFilter,
                },
              ]
            : []),
        ],
        pagination: { start: 0, limit, pageSize },
      },
      [categoryFilter, lastSearchFetch, limit]
    );

  const [categories, categoriesLoading] =
    useFetchCollection<EventsCategoryType>(allInternalCategoriesInterface, []);

  const onLoadMoreButtonClick =
    (newsMeta.pagination.total ?? 1) > limit
      ? () => {
          setLimit((prevState) => prevState + pageSize);
        }
      : undefined;

  return (
    <AuthenticatedPage hideHeader>
      <div className="container py-4">
        <HomeButton />
      </div>
      <CategoryFilter
        categories={categories.map((cat) => ({
          label: cat.attributes.name,
          value: cat.attributes.slug,
        }))}
        categoryFilter={categoryFilter}
        allLabel={"Alle Downloads"}
        setCategoryFilter={(slug) => setCategoryFilter(slug)}
      />
      <div className="container bg-beige pt-10 pb-15">
        <div className="relative mb-5">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
            className="ws-input pr-12 text-sm"
            placeholder="Begriff suchen"
          />
          <i className="ws-icons ws-icons-suche absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer text-xl pointer-events-none" />
        </div>
        <div className="mt-10">
          <Accordion
            items={internalNews.map((item) => ({
              name: item.attributes.title,
              text: item.attributes.teaser,
              link: item.attributes.content?.some(
                (item) => item.__component === "other.text"
              )
                ? `/internal/${item.attributes.slug}`
                : item.attributes.content?.some(
                    (item) => item.__component === "other.download"
                  )
                ? process.env.REACT_APP_API_URL +
                  (
                    item.attributes.content.find(
                      (item) => item.__component === "other.download"
                    ) as DownloadType
                  ).file.data.attributes.url
                : undefined,
            }))}
          />
          {onLoadMoreButtonClick && (
            <div className="mt-8 flex justify-center">
              <Button
                color={ButtonColor.INVERTED}
                onClick={onLoadMoreButtonClick}
                className="w-52"
              >
                Mehr anzeigen
              </Button>
            </div>
          )}
        </div>
      </div>
    </AuthenticatedPage>
  );
};
