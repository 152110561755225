import { NewsItemType } from "../../../models/NewsItem";
import { Spinner } from "../../other/Spinner/Spinner";
import "swiper/css";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import Swiper, { Autoplay, Controller, Pagination } from "swiper";
import { Tag } from "../../other/Tag/Tag";
import { useState } from "react";
import { NewsCard } from "../../other/NewsCard/NewsCard";
import { Button, ButtonColor } from "../../other/Button/Button";
import { Link } from "react-router-dom";

interface NewsTeaserProps {
  news: NewsItemType[];
  isLoading?: boolean;
}

export const NewsTeaser = (props: NewsTeaserProps) => {
  const [firstSwiper, setFirstSwiper] = useState<Swiper>();
  const [secondSwiper, setSecondSwiper] = useState<Swiper>();
  return (
    <div>
      <h1 className="mb-8 text-center font-headline text-h1">News</h1>
      {props.isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="isolate overflow-hidden rounded-lg shadow-alert">
            <SwiperComponent
              modules={[Autoplay, Controller]}
              controller={{
                control:
                  secondSwiper && !secondSwiper.destroyed
                    ? secondSwiper
                    : undefined,
              }}
              onSwiper={setFirstSwiper}
              className=""
              loop
              grabCursor
              autoplay={{ delay: 5000 }}
              speed={800}
            >
              {props.news.map((newsItem) => (
                <SwiperSlide key={newsItem.id}>
                  <Link to={`/news/${newsItem.attributes.slug}`}>
                    <div
                      className="relative flex aspect-square flex-col justify-between bg-cover bg-no-repeat p-2.5"
                      style={{
                        backgroundImage:
                          newsItem.attributes.mainImage.data &&
                          `url(${process.env.REACT_APP_API_URL}${newsItem.attributes.mainImage.data.attributes.url})`,
                      }}
                    >
                      <div className="absolute left-0 bottom-0 h-52 w-full bg-gradient-to-t from-brown to-transparent" />
                      {newsItem.attributes.category.data ? (
                        <Tag
                          name={
                            newsItem.attributes.category.data.attributes.name
                          }
                        />
                      ) : (
                        <div />
                      )}
                      <div className="relative p-2.5 text-white">
                        <h3
                          className="mb-2.5 font-headline text-h3"
                          style={{ textShadow: "0 0 5px 0 rgba(0,0,0,0.75)" }}
                        >
                          {newsItem.attributes.headline}
                        </h3>
                        <p className="text-copy">
                          {newsItem.attributes.teaser}
                        </p>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </SwiperComponent>
          </div>
          <div className="mt-5">
            <SwiperComponent
              modules={[Controller, Pagination]}
              onSwiper={setSecondSwiper}
              controller={{
                control:
                  firstSwiper && !firstSwiper.destroyed
                    ? firstSwiper
                    : undefined,
              }}
              loop
              className="h-2.5"
              pagination={{ clickable: true }}
            >
              {props.news.map((bullet) => (
                <SwiperSlide key={bullet.id} />
              ))}
            </SwiperComponent>
          </div>
        </div>
      )}
      <div className="mt-10 grid auto-rows-fr grid-cols-1 space-y-5">
        {props.news.map((newsItem) => (
          <NewsCard {...newsItem} key={newsItem.id} showTeaser />
        ))}
      </div>
      <div className="mt-8 flex justify-center">
        <Button color={ButtonColor.INVERTED} link="/news" className="w-52">
          Mehr entdecken
        </Button>
      </div>
    </div>
  );
};
