import { Button, ButtonColor } from "../../other/Button/Button";
import clsx from "clsx";
import { Link } from "react-router-dom";
import React, { useState } from "react";

export interface UserDataType {
  firstName: string;
  lastName: string;
  employeeNumber: string;
  birthday: string;
}

interface FindUserByBaseDataProps {
  input: UserDataType;
  onUpdate: (update: Partial<UserDataType>) => void;
  onSubmit: () => void;
  error: string;
  isLoading: boolean;
}

export const FindUserByBaseData = (props: FindUserByBaseDataProps) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.onSubmit();
  };

  return (
    <>
      <form className="mt-10" onSubmit={handleSubmit}>
        <input
          type="text"
          required
          className="ws-input mb-5"
          placeholder="Vorname"
          value={props.input.firstName}
          onChange={(event) =>
            props.onUpdate({
              firstName: event.target.value,
            })
          }
        />
        <input
          type="text"
          required
          className="ws-input mb-5"
          placeholder="Nachname"
          value={props.input.lastName}
          onChange={(event) =>
            props.onUpdate({
              lastName: event.target.value,
            })
          }
        />
        <input
          type="text"
          required
          className="ws-input mb-5"
          placeholder="Mitarbeiternummer"
          value={props.input.employeeNumber}
          onChange={(event) =>
            props.onUpdate({
              employeeNumber: event.target.value,
            })
          }
        />
        <input
          type="text"
          required
          className="ws-input mb-5"
          placeholder="Geburtsdatum (z.B. 06.10.1980)"
          value={props.input.birthday}
          pattern="\d{1,2}.\d{1,2}.\d{4}"
          title="Das Geburtsdatum muss in der Form TT.MM.JJJJ eingegeben werden, z.B. 06.10.1980"
          onChange={(event) =>
            props.onUpdate({
              birthday: event.target.value,
            })
          }
        />
        <div className="mt-4 flex flex-col items-center">
          {props.error && (
            <p className="mb-4 -mt-4 text-center text-copy-small text-danger">
              {props.error}
            </p>
          )}
          <Button
            color={ButtonColor.PRIMARY}
            type="submit"
            disabled={props.isLoading}
            className={clsx([
              "mb-8 w-52",
              props.isLoading && "bg-dark-grey cursor-progress",
            ])}
          >
            Registrieren
            {props.isLoading && (
              <div
                className="inline-block ml-3 h-6 w-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-white"
                role="status"
                aria-label="loading"
              />
            )}
          </Button>
        </div>
      </form>
      <div className="flex justify-center">
        <Link to="/login" className="font-bold">
          Zum Login
        </Link>
      </div>
    </>
  );
};
