import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { OverviewHero } from "../components/sections/OverviewHero/OverviewHero";
import { useFetchCollection } from "../utils/fetchUtils";
import { NewsItemType } from "../models/NewsItem";
import {
  heroNewsInterface,
  latestNewsInterface,
} from "../utils/fetchData/fetchNews";
import { CategoryFilter } from "../components/sections/CategoryFilter/CategoryFilter";
import { useEffect, useState } from "react";
import { allNewsCategoriesInterface } from "../utils/fetchData/fetchCategories";
import { NewsCategoryType } from "../models/NewsCategory";
import { NewsList } from "../components/sections/NewsList/NewsList";

const NewsPage = () => {
  const pageSize = 10;
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  const [newsInitiallyLoaded, setNewsInitiallyLoaded] =
    useState<boolean>(false);
  const [limit, setLimit] = useState<number>(pageSize);

  useEffect(() => {
    setLimit(pageSize);
  }, [categoryFilter]);

  const [heroNews, heroNewsLoading] = useFetchCollection<NewsItemType>(
    heroNewsInterface,
    []
  );

  const [news, newsLoading, newsMeta] = useFetchCollection<NewsItemType>(
    {
      ...latestNewsInterface,
      filter: categoryFilter
        ? [
            {
              collectionType: "category",
              attribute: "slug",
              value: categoryFilter,
            },
          ]
        : [],
      pagination: { start: 0, limit, pageSize },
    },
    [categoryFilter, limit]
  );

  const [categories, categoriesLoading] = useFetchCollection<NewsCategoryType>(
    allNewsCategoriesInterface,
    [categoryFilter]
  );

  useEffect(() => {
    if (!newsInitiallyLoaded && !newsLoading) {
      setNewsInitiallyLoaded(true);
    }
  }, [news, newsLoading]);

  const onLoadMoreButtonClick =
    (newsMeta.pagination.total ?? 1) > limit
      ? () => {
          setLimit((prevState) => prevState + pageSize);
        }
      : undefined;

  return (
    <AuthenticatedPage hideHeader>
      <OverviewHero items={heroNews} />
      <CategoryFilter
        categories={categories.map((cat) => ({
          label: cat.attributes.name,
          value: cat.attributes.slug,
        }))}
        categoryFilter={categoryFilter}
        setCategoryFilter={(slug) => setCategoryFilter(slug)}
      />
      <NewsList
        news={news}
        loading={newsLoading && !newsInitiallyLoaded}
        onLoadMoreButtonClick={onLoadMoreButtonClick}
      />
    </AuthenticatedPage>
  );
};

export default NewsPage;
