import { NewsItemType } from "../../../models/NewsItem";
import { BackButton } from "../../other/BackButton/BackButton";
import { ImageCard } from "../../other/ImageCard/ImageCard";
import { Markdown } from "../../other/Markdown/Markdown";
import { ContentImages } from "./ContentImages/ContentImages";
import { ArticleFooter } from "./ArticleFooter/ArticleFooter";
import { EventType } from "../../../models/Event";
import dayjs from "dayjs";
import { Button, ButtonColor } from "../../other/Button/Button";
import { InternalNewsItemType } from "../../../models/InternalNewsItem";

export const ArticleContent = (
  props: (NewsItemType | EventType) & {
    checkConfirmation?: () => void;
    download?: InternalNewsItemType["attributes"]["download"];
  }
) => {
  return (
    <div className="bg-beige native-pt">
      <div className="fixed top-0 left-0 z-50 native-pt w-full bg-beige" />
      <div className="container pt-5 pb-16">
        <BackButton className="mb-10" />
        {props.attributes.category.data && (
          <h2 className="text-preline uppercase font-bold text-secondary mb-2.5">
            {props.attributes.category.data.attributes.name}
            {"date" in props.attributes &&
              ` - ${dayjs(props.attributes.date).format("DD.MM.YYYY")}`}
          </h2>
        )}
        <h1 className="text-h2 font-headline">
          {"headline" in props.attributes
            ? props.attributes.headline
            : props.attributes.title}
        </h1>
        {"mainImage" in props.attributes && props.attributes.mainImage.data && (
          <ImageCard className="mt-8" {...props.attributes.mainImage.data} />
        )}
        {props.download?.data && (
          <Button
            color={ButtonColor.PRIMARY}
            link={
              process.env.REACT_APP_API_URL + props.download.data.attributes.url
            }
            className="mt-8"
          >
            {props.download.data.attributes.name}
          </Button>
        )}
        {"content" in props.attributes &&
          props.attributes.content.map((content, index) => {
            if (content.__component === "other.text" && "text" in content) {
              return (
                <Markdown className="mt-8" key={index}>
                  {content.text}
                </Markdown>
              );
            }
            if (content.__component === "other.images" && "images" in content) {
              return <ContentImages key={index} images={content.images} />;
            }
            if (
              content.__component === "other.download" &&
              "file" in content &&
              content.file.data
            ) {
              return (
                <Button
                  color={ButtonColor.PRIMARY}
                  link={
                    process.env.REACT_APP_API_URL +
                    content.file.data.attributes.url
                  }
                  key={index}
                  className="mt-8"
                >
                  {content.buttonText}
                </Button>
              );
            }
            return null;
          })}
        {props.checkConfirmation && (
          <div>
            <Button
              color={ButtonColor.PRIMARY}
              onClick={props.checkConfirmation}
              className="mt-8"
            >
              Ich habe diesen Beitrag gelesen
            </Button>
          </div>
        )}
        <ArticleFooter />
      </div>
    </div>
  );
};
