import { AuthenticatedPage } from "../components/layouts/AuthenticatedPage/AuthenticatedPage";
import { useFetchCollection } from "../utils/fetchUtils";
import React, { TouchEventHandler, useMemo, useState } from "react";
import { useLazyInput } from "../utils/useLazyInput";
import { KnowledgeItemType } from "../models/KnowledgeItem";
import { knowledgeItemsOverviewInterface } from "../utils/fetchData/fetchKnowledgeItems";
import clsx from "clsx";
import { Accordion } from "../components/sections/Accordion/Accordion";
import { Button, ButtonColor } from "../components/other/Button/Button";
import { HomeButton } from "../components/other/HomeButton/HomeButton";

export const KnowledgePage = () => {
  const [searchInput, setSearchInput, lastSearchFetch] = useLazyInput();
  const [activeChar, setActiveChar] = useState<string>("A");

  const [knowledgeItems] = useFetchCollection<KnowledgeItemType>(
    {
      ...knowledgeItemsOverviewInterface,
    },
    []
  );

  const handleTouchMove: TouchEventHandler = (event) => {
    const elToFindId = document
      .elementFromPoint(
        event.targetTouches.item(0).pageX,
        event.targetTouches.item(0).pageY
      )
      ?.id.split("-")[1];
    if (elToFindId) {
      setActiveChar(elToFindId);
    }
  };

  const filteredItems = useMemo(() => {
    if (lastSearchFetch) {
      return knowledgeItems.filter((item) =>
        item.attributes.name
          .toLowerCase()
          .includes(lastSearchFetch.toLowerCase())
      );
    }
    return knowledgeItems.filter((item) =>
      item.attributes.name.startsWith(activeChar)
    );
  }, [knowledgeItems, activeChar, lastSearchFetch]);

  return (
    <AuthenticatedPage hideHeader>
      <div className="container py-4">
        <HomeButton />
      </div>
      <div className="relative z-10 bg-white shadow-banner">
        <div
          className="h-2.5 w-full bg-full"
          style={{
            backgroundImage: "url('/img/goldlinie_oben.png')",
          }}
        />
        <div
          className={clsx([
            "flex justify-between font-bold text-secondary text-sm container py-5",
            lastSearchFetch && "text-brown pointer-events-none",
          ])}
          onTouchMove={handleTouchMove}
        >
          {Array.from(Array(26))
            .map((_, i) => i + 65)
            .map((alpha) => String.fromCharCode(alpha))
            .map((char) => (
              <div
                key={char}
                id={`glossar-${char}`}
                onClick={() => setActiveChar(char)}
                onTouchStart={() => setActiveChar(char)}
                className={clsx(activeChar === char && "text-brown")}
              >
                {char}
              </div>
            ))}
        </div>
        <div
          className="h-2.5 w-full bg-full"
          style={{
            backgroundImage: "url('/img/goldlinie_unten.png')",
          }}
        />
      </div>
      <div className="container bg-beige py-10">
        <div className="relative">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
            className="ws-input pr-12 text-sm"
            placeholder="Begriff suchen"
          />
          <i className="ws-icons ws-icons-suche absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer text-xl pointer-events-none" />
        </div>
      </div>
      <div className="container pb-15">
        <Accordion
          items={filteredItems.map((knowledgeItem) => ({
            text: knowledgeItem.attributes.text,
            name: knowledgeItem.attributes.name,
            link: knowledgeItem.attributes.link,
          }))}
        />
        {activeChar.charCodeAt(0) < 90 && (
          <div className="flex justify-center mt-5">
            <Button
              color={ButtonColor.INVERTED}
              className="w-52"
              onClick={() =>
                setActiveChar(String.fromCharCode(activeChar.charCodeAt(0) + 1))
              }
            >
              Weiter zu {String.fromCharCode(activeChar.charCodeAt(0) + 1)}
            </Button>
          </div>
        )}
      </div>
    </AuthenticatedPage>
  );
};
