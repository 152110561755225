import { DefaultLayout } from "../components/layouts/DefaultLayout/DefaultLayout";
import React, { useState } from "react";
import { Button, ButtonColor } from "../components/other/Button/Button";
import clsx from "clsx";

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const passwordForgotFetch = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/forgot-password`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );
      setIsLoading(false);
      if (passwordForgotFetch.ok) {
        setSuccess(true);
      }
    } catch (e: any) {
      setIsLoading(false);
      setError(e.toString());
    }
  };
  return (
    <DefaultLayout>
      <div className="flex flex-1 justify-center bg-beige py-20 pt-24">
        <div className="max-w-[17.5rem]">
          <h1 className="mb-6 text-center font-headline text-h2">
            Passwort
            <br />
            Zurücksetzen
          </h1>
          {success ? (
            <>
              <p className="text-center mt-20">
                Wir haben eine E-Mail an ihre E-Mail Addresse geschickt. Bitte
                folgen Sie den Anweisungen, um das Passwort zurückzusetzen.
              </p>
              <div className="mt-8 flex flex-col items-center">
                <Button
                  color={ButtonColor.SECONDARY}
                  link="/login"
                  type="submit"
                  className="mb-8 w-52"
                >
                  Zum Login
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="text-center">
                Bitte geben Sie Ihre E-Mail Adresse ein, mit der Sie Ihren
                Account erstellt haben.
              </p>
              <form className="mt-10" onSubmit={handleSubmit}>
                <input
                  type="email"
                  required
                  className="ws-input mb-5"
                  placeholder="E-Mail"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <div className="mt-4 flex flex-col items-center">
                  {error && (
                    <p className="mb-4 -mt-4 max-w-[10rem] text-center text-copy-small text-danger">
                      {error}
                    </p>
                  )}
                  <Button
                    color={ButtonColor.PRIMARY}
                    type="submit"
                    disabled={isLoading}
                    className={clsx([
                      "mb-8 w-52",
                      isLoading && "bg-dark-grey cursor-progress",
                    ])}
                  >
                    Absenden
                    {isLoading && (
                      <div
                        className="inline-block ml-3 h-6 w-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-white"
                        role="status"
                        aria-label="loading"
                      />
                    )}
                  </Button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};
