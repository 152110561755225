import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { UserContext } from "../../../utils/UserContext";

export const AppUrlListener = () => {
  const { localUser } = useContext(UserContext);
  let history = useHistory();
  const [location, setLocation] = useState<string>(history.location.pathname);

  history.listen((location) => setLocation(location.pathname));

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // slug = /tabs/tab2
      const slug = event.url.split(".app").pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  useEffect(() => {
    if (location === "/" && !localUser) {
      history.push("/login");
    }
  }, [location]);

  return null;
};
